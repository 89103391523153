import styled from 'styled-components';
import * as vars from './Variable';


//button default set
export const Button = styled.button.attrs(props => ({
}))`
    border:0;
    outline:0;
    margin:0;
    padding: 0;
    background-color: inherit;
    cursor: pointer;
    ${vars.flex_j_center}
    flex-direction: ${props => props.isColumn ? "column" : "row"};
`;

export const ColoredButton = styled(Button).attrs(props => ({
    style: {
        backgroundColor: props.bg ? props.bg : vars.blue_1884ff,
        border: props.bg ? props.bg : `2px solid ${vars.blue_1884ff}`,
        color: props.color ? props.color : "white",
        borderRadius: props.borderRadius ? props.borderRadius : null,
        margin: props.margin && props.margin,
    }
}))`
padding: .18rem .3rem;
font-size: ${vars.fontSizeSm};
transition: .1s ease-in-out;
&:hover{
    background-color: ${props => props.color ? props.color : "white"} !important;
    color: ${props => props.bg ? props.bg : vars.blue_1884ff} !important;
    border-color: ${props => props.bg ? props.bg : vars.blue_1884ff} !important;
}
`;
// height: 1.5rem;

export const ColoredLgButton = styled(ColoredButton)`
padding: .5rem 1rem;
font-size: ${vars.fontSizeBase};
`;

export const GradientButton = styled(ColoredButton).attrs(props => ({
    style: {
        border: props.border && `1px solid ${props.border}`
    }
}))`
padding: 1rem;

&:hover{
    border-color: #bebebe;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
&:focus{
    background: linear-gradient(135deg, white, #e2e2e2);
}
`;
//anchore default set
export const A = styled.a`
    text-decoration: none;
    color: black;
    font-weight: 300;

    &:hover{
        cursor: pointer;
    }
`;

//bigest container
export const ContainerBase = styled.div.attrs(props => ({
    style: {
        background: `url(${props.img}) no-repeat center center/cover`,
    }
}))`
min-width: 960px;
min-height: 768px;
overflow: hidden;
height: 100vh;
position: relative;
`;



//statusbar
export const StatusbarContainer = styled.header.attrs(props => ({
    style: {
        zIndex: props.zIndex && props.zIndex,
    }
}))`
position: relative;
`;

export const StatusbarNav = styled.nav`
width: 100%;
height: ${vars.navTop_height};
background-color: ${vars.navTop_bgColor};
transition: ${vars.trans_1};
display: flex;
`;
// ${vars.flex_j_between}
// position: relative;

// export const StatusbarApp = styled.ul`
// width: 100%;
// opacity: 1;
// height: ${vars.navTop_height};
// transition: ${vars.trans_2};
// overflow: hidden;
// `;
// width: calc(50% - ${vars.navTop_height} - 2px);

// export const StatusbarActiveUl = styled.ul`
// ${vars.flex_j_start}
// ${vars.p_r_1}
// height: ${vars.navTop_height};
// width: max-content;
// `;

// export const StatusbarActiveLi = styled.li`
// width: ${vars.navTop_li_width};
// height: 100%;
// transition: ${vars.trans_2};
// ${vars.flex_none}
// `;


export const StatusbarButton = styled(Button).attrs(props => ({
    style: {
        transform: props.isScrewbar && "skewX(30deg)",
        background: props.current && vars.navTop_icon_hoverEffect,
    }
}))`
height: 100%;
width: 100%;
position: relative;
&:hover{
    background: ${props => props.isScrewbar ? vars.modal_btn_hoverEffect : vars.navTop_icon_hoverEffect};
};
`;


//main btn

export const MainBtn = styled(Button)`
width: 58px;
perspective: 100px;
${vars.flex_j_center}
height: 33.4px;
background: white;
`;

export const MainBtnShape = styled.div`
    width: 100%;
    height: 68px;
    background: ${vars.gray_4d4d4d};
    transform: rotateX(-50deg);
    box-shadow: 0 2px 3px rgba(0,0,0,.8);
    border-radius: 3px;
    transition: ${vars.trans_1};
`;


//search bar
export const SearchWrapper = styled.div`
    min-width: 252px;
    margin-left: -8px;
`;


//icon
export const Icon = styled.i.attrs(props => ({
    style: {
        backgroundImage: `url(${props.img})`,
    }
}))`
background-size: contain;
display: inline-block;
background-repeat: no-repeat;
background-position: center;
${vars.iconBaseSize}
min-width: ${vars.iconBaseSizeWidth};
`;

export const IconHamburger = styled(Icon)`
width: 100%;
min-width: 100%;
height: 100%;
position: absolute;

&:hover{
    background-image: url(${vars.icon_finestra}) !important;
}
`;


export const IconLg = styled(Icon)`
${vars.iconLgSize}
min-width: ${vars.iconLgSizeWidth};
`;


// export const IconXl = styled(Icon)`
// ${vars.iconXlSize}
// min-width: ${vars.iconXlSizeWidth};
// `;

export const IconFull = styled(Icon)`
${vars.iconFullSize}
min-width: ${vars.iconFullWidth};
`;

export const IconFullCover = styled(IconFull)`
background-size: cover;
`;

export const IconSm = styled(Icon)`
${vars.iconSmSize}
min-width: ${vars.iconSmSizeWidth};
`;

export const IconSemi = styled(Icon)`
    ${vars.iconSemiSize}
    min-width: ${vars.iconSemiSizeWidth};
`;

export const IconXs = styled(Icon)`
${vars.iconXsSize}
min-width: ${vars.iconXsSizeWidth};
`;

export const IconXxs = styled(Icon)`
${vars.iconXxsSize}
min-width: ${vars.iconXxsSizeWidth};
`;

export const IconTiny = styled(Icon)`
${vars.iconTinySize}
min-width: ${vars.iconTinySizeWidth};
`;

export const IconActiveDiv = styled.div.attrs(props => ({
    style: {
        display: props.show ? "block" : "none",
        background: props.show && "linear-gradient(90deg,rgba(255,255,255,0), white ,rgba(255,255,255,0))"
    }
}))`
position: absolute;
width: 100%;
height: 2px;
bottom: 0;
transition: ${vars.trans_1};
`;
// background: linear-gradient(90deg,rgba(255,255,255,0),#fff,rgba(255,255,255,0));


//background
//container of bg icons.
// export const BgContainer = styled.div`
// ${vars.bgHeight}
// transition: ${vars.trans_1};
// display: flex;
// flex-wrap: wrap;
// padding: ${vars.BgCotainerP};
// `;
// ${vars.visible} 
// width: 100%;
// position: relative;

//button of bg icon

export const BgIconContainer = styled.div.attrs(props => ({
    style: {
        // display: props.show ? "block" : "none",
    }
}))`
display: block;
border: 1px solid transparent;
width: 75px;
height: 75px;
margin: 1px;
`;


export const BgIconButton = styled(Button).attrs(props => ({
    style: {
        // border: props.isFocus || '1px dotted #fff',
    }
}))`
border: 1px solid transparent;
transition: ${vars.trans_1};
cursor: default;
padding: .5rem .2rem;

&:hover{
    background-color: rgba(255, 255, 255, 0.3);
}

&:focus{
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.3);
}
`;
// height: auto;
// height: 100%;
export const P = styled.p`
font-weight: 300;
font-size: ${vars.fontSizeSm};
`;

export const BgIconName = styled(P).attrs(props => ({
    style: {
        height: props.isFocused ? "auto" : "21px",
    }
}))`
min-height: 21px;
width: 68px;
margin-top: .25rem;
color: white;
text-shadow: 0 1px 2px black;
text-align: center;
line-height: 1.1rem;
overflow: hidden;
overflow-wrap: break-word;
`;
// word-break: break-all;
// overflow: hidden;

//modal
export const Modal = styled.div.attrs(props => ({
    style: {
        /*top: props.y ? `${props.y}px` : (props.y === 0) && `${vars.navTop_height}px`,*/
        top: props.y ? `${props.y}px` : (props.y === 0) && `0`,
        left: props.x ? `${props.x}px` : (props.x === 0) && 0,
        width: (props.width === "100%") ? "100%" : props.width ? `${props.width}px` : "max-content",
        /*height: (props.height === "100%") ? `calc(100% - ${vars.navTop_height}px)` : props.height ? `${props.height}px` : "max-content",*/
        height: (props.height === "100%") ? `100%` : props.height ? `${props.height}px` : "max-content",
        zIndex: props.zIndex && props.zIndex,
    }
}))`
    box-shadow: 0 2px 3px rgba(0, 0, 0, .6);
    position: absolute;

    &:hover{
        cursor: default;
    }
`;

export const ModalSttNamebar = styled.div.attrs(props => ({
    style: {

        alignItems: props.align ? props.align : "center",
    }
}))`
    display: flex;
    flex-direction: row;
    flex: 1;

    &:hover{
        cursor: pointer;
    }
`;

export const DropdownModal = styled(Modal).attrs(props => ({
    style: {
        height: props.height ? `calc(${props.height}px - ${vars.modalHeight}px)` : 0,
        right: props.right === 0 && 0,
        width: props.width === 0 ? 0 : props.width ? `${props.width}px` : "367px",
    }
}))`
top: calc(${vars.modalHeight}px + 5.5px);
transition: ${vars.trans_2};
background: white;
overflow: hidden;
`;
// width: 367px;

export const DropdownLeft = styled.div`
    width: 50px;
    background: ${vars.gray_ececec};
    display: grid;
    height: 100%;
    justify-items: center;
    align-content: flex-start;
    position: relative;
        button{
            padding: .7rem 0;

            &:hover{
                background: ${vars.gray_dbdbdb};
            }

            &:last-child{
                position: absolute;
                bottom: 0;
                height: 42.4px;
            }
        }
        `;
// height: calc(100% - 2rem);
// ${vars.flex_j_start}
// ${vars.flex_column}
// padding: 1rem 0;

// export const ModalSttbarUl = styled.ul.attrs(props => ({
//     style: {
//         backgroundColor: props.isSttbar && "rgba(0, 0, 0, .6)",
//         transform: props.isSttbar && "skewX(-30deg)",
//         marginRight: props.isSttbar && "-6px",
//         padding: props.isSttbar && "0 6px"
//     }
// }))`
//     height: 100%;
//     ${vars.flex_j_start}
//     `;

// ${}



export const Ul = styled.ul.attrs(props => ({
    style: {
        height: props.height && `${props.height}px`,
        justifyContent: props.end ? "flex-end" : "flex-start",
        flexDirection: props.column ? "column" : "row",
        alignItems: props.align ? props.align : "center",
        backgroundColor: props.bg && vars.gray_ececec
    }
}))`
    width: 100%;
    display: flex;
    
    &:hover{
        background-color: ${props => props.isHoverEffect && vars.gray_ececec};
    }

    `;

//margin-left: auto;
export const Screwbar = styled(Ul)`
height: 100%;
background-color: rgba(0, 0, 0, .6);
transform: skewX(-30deg);
margin-right: -6px;
width: 140px;
`;
// padding: 0 6px;

export const Li = styled.li.attrs(props => ({
    style: {
        width: props.width ? props.width : "max-content",
        flex: props.full && 1,
        marginRight: props.marginRight === 0 ? 0 : ".2rem",
        justifyContent: props.start ? "flex-start" : "center",

    }
}))`
height: 100%;
display: flex;
align-items: center;
position: relative;
visibility: ${props => props.hide ? "hidden" : "visible"};
`;


export const ColLi = styled.li.attrs(props => ({
    style: {
        width: props.width ? `${props.width}%` : "100%",
        borderRight: props.borderR && `1px solid ${vars.gray_dbdbdb}`,
        borderBottom: props.borderB && `1px solid ${vars.gray_dbdbdb}`,
        height: props.height && `${props.height}px`,
    }
}))`
    position: relative;
    ${vars.flex_j_start}
    flex-wrap: no-wrap;
    overflow: hidden;
    white-space: nowrap;

    &:last-child{
        border-right: ${props => props.borderR && 0};
    }
`;


// export const ModalSttbarLi = styled.li.attrs(props => ({
//     style: {
//         width: props.width ? props.width : "max-content",
//     }
// }))`
//     height: 100%;
//     ${vars.flex_j_center}
//     color: white;
//     font-weight: 400;
// `;



//modal's container
// export const ContentContainer = styled.section.attrs(props => ({
//     style: {
//         backgroundColor: props.bg ? props.bg : "white",
//     }
// }))`
// height: calc( 100% - ${vars.modalHeight} );
// overflow: visible;
// `;


export const Row = styled.div.attrs(props => ({
    style: {
        height: props.height ? props.height : "max-content",
        // width: props.width ? props.width : "100%",
        padding: props.padding === 0 ? 0 : props.padding ? props.padding : vars.rowPadding,
        width: props.padding === 0 ? "100%" : props.padding ? `calc(100% - ${props.padding} * 2)` : `calc(100% - ${vars.rowPadding} * 2)`,
        // flexDirection: props.column ? "column" : "row",
        justifyContent: props.center ? "center" : props.between ? "space-between" : props.start ? "flex-start" : null,
        alignItems: props.align && props.align,
        boxShadow: props.lightShadow ? "0 0 10px rgba(255,255,255,.2)" : props.darkShadow ? "0 1px 3px rgba(0,0,0,.1) inset" : null,
        overflow: props.visible ? "visible" : "hidden",
    }
}))`
display: flex;
flex-wrap: wrap;
background: ${props => props.bg && props.bg};

&hover{
    background: ${props => props.hoverbg & props.hoverbg} !important;
}
`;
// overflow: hidden;
// width: 100%;
// overflow-y: auto;
// ${vars.h_02}


export const MlAuto = styled.div`
    margin-left: auto;
    margin-right: 0;
    display: flex;
`;

// export const CollapseRow = styled(Row).attrs(props => ({
// }))`
// height:${props => props.collapsed ? "0" : "max-content"};
// overflow: hidden;
// transition: ${vars.trans_2};
// box-shadow: 0 0 10px rgba(255,255,255,.2);
// border-radius: 2px;
// `;

// export const ModalSttbarContainer = styled(Row)`
//     cursor: pointer;
//     overflow: hidden;
// `;


// export const ModalSttbarContainer = styled.header.attrs(props => ({
//     style: {
//         background: props.current ? vars.modalSttbarCurrentColor : vars.modalSttbarColor,

//     }
// }))`
//     height: ${vars.modalHeight};
//     cursor: pointer;
//     overflow: hidden;
//     ${vars.flex_j_between}
//     border-bottom: 0;
// `;
// border: .2px solid grey;
// width: 100%;

export const Container = styled.div.attrs(props => ({
    style: {
        backgroundColor: props.bg && props.bg,
        // padding: props.padding ? props.padding : vars.containerPadding,
        // width: props.width ? `calc(${props.width} - ${vars.containerPadding} * 2)` : `calc(100% - ${vars.containerPadding} * 2)`,
        // height: props.height ? props.height : `calc(100% - ${vars.modalHeight} - ${vars.containerPadding} * 2)`,
        height: props.height ? props.height : `calc(100% - ${vars.modalHeight}px)`,
        overflowY: props.flowy && "auto",
        color: props.color && props.color,
    }
}))`
position: relative;
width: 100%;
`;

export const FlexibleWidth = styled(Container).attrs(props => ({
    sytle: {
        // width: props.width && props.width,
    }
}))`
    width: ${props => props.width && props.width}px;
    height: 100% !important;
    overflow: hidden;
    transition: ${props => props.transition && vars.trans_1};
    `;
// height: 100%;
// padding: ${vars.containerPadding};


// export const ContainerY = styled.div.attrs(props => ({
//     style: {
//         width: props.width ? `${props.width}px` : `100%`,
//         height: props.height ? `${props.height}` : `100%`,
//     }
// }))`
//     overflow-y: auto;
//     position: relative;
// `;

export const LinkableText = styled(A).attrs(props => ({
    style: {
        backgroundColor: props.bg ? props.bg : props.isFocused && vars.FMFocusedListBg,
        width: props.width && props.width,
        padding: props.padding ? props.padding : "4px",
        justifyContent: props.center && "center",
    }
}))`
    display: flex;
    font-size: ${vars.fontSizeSm};
    cursor: pointer;
    
    &:hover{
        background-color: ${props => props.hoverbg ? props.hoverbg : vars.FMFocusedListBg} !important;
    }
    
    padding-left: ${props => props.depth > 0 ? props.depth : props.padding ? props.padding : 0}rem !important;
    `;
// overflow: hidden;

export const UnLinkableText = styled(LinkableText).attrs(props => ({
    style: {
        display: props.display && props.display,
    }
}))`
    cursor: default !important;
`;
// white-space: nowrap;

// + ul{
//     li{
//         width: 100%;
//         a{

//         }
//     }
// }
// transform: props.show ? "scaleY(1)" : "scaleY(0)",
// display: props.show ? "block" : "none",
// transform-origin:top;
export const ToggleUl = styled.ul.attrs(props => ({
    style: {
        height: props.show ? `${props.height}px` : 0
    }
}))`
    transition: .2s ease;
    overflow: hidden;
`;


export const ToggleList = styled.li.attrs(props => ({
    style: {
        display: props.show ? "block" : "none",
    }
}))`
white-space: nowrap;
`;

export const DirectoryBar = styled.div.attrs(props => ({
    style: {
        transform: props.isFocused && "rotate(90deg)",
    }
}))`
    ${vars.flex_j_center}
    min-width: 1.5rem;
    width: 1.5rem;
    min-height: 1rem;
    height: 1rem;
    margin: 0 2px;
    transition: .2s ease-in-out;

    div{
        background-color: ${vars.gray_979797};
        opacity: ${props => props.isFocused ? 1 : .4};
        height: 12px;
        width: 2px;
        border-radius: 1.5px;
    }

`;


//&:before
export const FlexFull = styled(Container)`
    flex: 1;
    border-left: .1px solid ${vars.gray_dbdbdb};
    height: 100% !important; 
    min-width: 150px;
`;



// export const ResultFilterBtn = styled.button`
//     width: 100%;
//     text-align: left;
// `;


export const Footer = styled.footer`
background: ${vars.gray_ececec};
box-shadow: 2px 0 3px rgba(0, 0, 0, .1);
height: 1.5rem;
${vars.flex_j_end}
padding-right: 1rem;
color: ${vars.gray_4d4d4d};
margin-top: auto;
margin-bottom: 0;
`;



// export const NaviUl = styled(Ul).attrs(props => ({
//     style:{
//     }
// }))`
// height: 100%;
// `;
// width: props.width && `${props.width}`



export const NaviButton = styled(Button)`
    height: 100%;
    width: 2rem;
    transition: ${vars.trans_1};
    &:hover{
        background: ${vars.gray_dbdbdb};
    }
`;

export const ActionBtns = styled(NaviButton)`
    background: ${vars.gray_ececec};
    width: 96px;
    border-radius: 2px;
    height: 28px;
    margin: 2px 0 2px 6px;
    transition: .1s;
    font-size: ${vars.fontSizeSm};
`;

export const Input = styled.input.attrs(props => ({
    type: props.type || "text",
    style: {
        padding: props.padding && props.padding,
        height: props.height && props.height,
        display: props.display && props.display,
    }
}))`
    outline: 0;
    border-radius: 1px;
    border: ${props => props.borderColor ? props.borderColor : ".2px solid #4d4d4d"};
    border-bottom: ${props => props.borderBottom ? `.2px solid ${props.borderBottom}` : null};
    background : ${props => props.bg && props.bg};
    background-position: ${props => props.position && props.position};
    background-size: ${props => props.type === "search" && "16px"};
    background-repeat: ${props => props.type === "search" && "no-repeat"};
    background-image: ${props => props.type === "search" && `url(${vars.Icon_search_b})`};
    width: 100%;

    
`;
// minWidth: props.minWidth && `${props.minWidth}px`,
// vertical-align: top;

export const Filter = styled.div`
    position: absolute;
    top: 3px;
    right: 0;
`;

export const FilterBtn = styled(Button).attrs(props => ({
}))`
    height: ${props => props.isFull ? "100%" : `${vars.filterBtnSize}px`};
    width: ${props => props.isFull ? "100%" : `${vars.filterBtnSize}px`};
    justify-content: flex-start;
    font-weight: 400;
    font-size: ${vars.fontSizeXs};

    &:hover{
        cursor: ${props => props.noHoverCursor && "default"};
    }
    `;
// height: props.isOrderable ? "100%" : "26px"


// right: -10.52rem;
export const FilterBox = styled.div.attrs(props => ({
    style: {
        height: props.show ? `${props.height}px` : 0,
        opacity: props.show ? 1 : 0,
        width: props.show ? "10rem" : 0,
    }
}))`
position: absolute;
    top: -6px;
    left: 32.35px;
    background: #fff;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, .6);
    transition: .2s;
    overflow: hidden;
        ul{
            li{
                width: 100%;;
                height: ${vars.filterBtnSize}px;
                color: ${vars.gray_979797};

                button{
                    width: 100%;;
                    height: 100%;
                    font-size: ${vars.fontSizeSm};

                    &:hover{
                        background-color: ${vars.gray_ececec};
                        color: ${vars.gray_4d4d4d};
                    }
                }
            }
        }
`;


//tab
// height: 32px;
export const Tab = styled.a.attrs(props => ({
    style: {
        backgroundColor: props.isFocused ? "white" : vars.gray_ececec,
        color: props.isFocused ? vars.gray_4d4d4d : vars.gray_979797,
        display: props.display && props.display,
    }
}))`
    padding: 8px 32px;
    background: #fff;
    text-align: center;
    border-radius: 3px 3px 0 0;
    cursor: pointer;
    
    &:hover{
        color: ${vars.gray_4d4d4d} !important;
    }
    `;
// color: black;


export const Img = styled.img.attrs(props => ({
    src: props.src && props.src,
    width: props.width && props.width,
    height: props.height && props.height,
}))`
    object-fit: cover;
`;

// gridAutoColumns: props.column ? `${props.column}px` : "100%",
// align-content: start;
// justify-items: center;
export const CardContainer = styled.div.attrs(props => ({
    style: {
        gridAutoRows: props.row && `${props.row}px`,
        // gridTemplateColumns: props.column && `repeat(auto-fit, ${props.column}px)`,
        gridTemplateColumns: props.repeat && `repeat(${props.repeat}, 1fr)`,
        gridGap: props.gap && `${props.gap}px`,
        padding: props.padding && `${props.padding}px`
    }
}))`
display: grid;
`;
// display: flex;
// flex-wrap: wrap;
// justify-content: center;
// align-items: flex-start;
// grid-template-columns:repeat(auto-fit, 200px);
// flex: 1;
// overflow-y: auto;
// ***grid-auto-rows: setting height

export const Card = styled.a.attrs(props => ({
    style: {
        // width: props.width ? `calc(288px * ${props.width})` : "288px",
        // height: props.height ? `calc(132px * ${props.width})` : "132px",
        boxShadow: props.shadow && "0 1px 3px rgba(0, 0, 0, .3)",
        background: props.bg ? props.bg : "white",
        margin: props.margin && props.margin,
        padding: props.padding ? props.padding : ".5rem",
    }
}))`
${vars.flex_j_between}

&:hover{
    background-color: ${vars.gray_ececec} !important;
}
`;
// margin: .5rem;
// min-width: 288px;
// min-height: 132px;


export const ProfileImage = styled.div`
     width: calc(100% - 6px);
     height: calc(100% - 6px);
     border: 3px solid white;
     border-radius: 50%;
     overflow: hidden;
`;
export const Col = styled.div.attrs(props => ({
    style: {
        justifyContent: props.between ? "space-between" : props.center ? "center" : props.start ? "flex-start" : props.end ? "flex-end" : null,
        alignItems: props.align && props.align,
        flexDirection: props.column ? "column" : "row",
        padding: props.padding === 0 ? 0 : props.padding ? props.padding : vars.columnPadding,
        height: props.height && props.height,
        background: props.bg && props.bg,
    }
}))`
display: flex;
flex-wrap: wrap;
width: ${props => props.padding === 0 ? "calc(1 / 12 * 100%)" : props.padding ? `calc(1 / 12 * 100% - ${props.padding} * 2)` : `calc(1 / 12 * 100% - ${vars.columnPadding} * 2)`};
`;
// visibility: ${props => props.hide ? "hidden": "visible"};
// width: calc(1 / 12 * 100% - ${vars.columnPadding} * 2);
// padding: ${vars.columnPadding};
// flex-direction: ${props => props.column ? "column" : "row"}

export const Col2 = styled(Col)`
width: ${props => props.padding === 0 ? "calc(2 / 12 * 100%)" : props.padding ? `calc(2 / 12 * 100% - ${props.padding} * 2)` : `calc(2 / 12 * 100% - ${vars.columnPadding} * 2)`};
`;
export const Col3 = styled(Col)`
width: ${props => props.padding === 0 ? "calc(3 / 12 * 100%)" : props.padding ? `calc(3 / 12 * 100% - ${props.padding} * 2)` : `calc(3 / 12 * 100% - ${vars.columnPadding} * 2)`};
`;

export const Col4 = styled(Col)`
width: ${props => props.padding === 0 ? "calc(4 / 12 * 100%)" : props.padding ? `calc(4 / 12 * 100% - ${props.padding} * 2)` : `calc(4 / 12 * 100% - ${vars.columnPadding} * 2)`};
`;

export const Col5 = styled(Col)`
width: ${props => props.padding === 0 ? "calc(5 / 12 * 100%)" : props.padding ? `calc(5 / 12 * 100% - ${props.padding} * 2)` : `calc(5 / 12 * 100% - ${vars.columnPadding} * 2)`};
`;

export const Col6 = styled(Col)`
width: ${props => props.padding === 0 ? "calc(6 / 12 * 100%)" : props.padding ? `calc(6 / 12 * 100% - ${props.padding} * 2)` : `calc(6 / 12 * 100% - ${vars.columnPadding} * 2)`};
`;

export const Col7 = styled(Col)`
width: ${props => props.padding === 0 ? "calc(7 / 12 * 100%)" : props.padding ? `calc(7 / 12 * 100% - ${props.padding} * 2)` : `calc(7 / 12 * 100% - ${vars.columnPadding} * 2)`};
`;

export const Col8 = styled(Col)`
width: ${props => props.padding === 0 ? "calc(8 / 12 * 100%)" : props.padding ? `calc(8 / 12 * 100% - ${props.padding} * 2)` : `calc(8 / 12 * 100% - ${vars.columnPadding} * 2)`};
`;

export const Col9 = styled(Col)`
width: ${props => props.padding === 0 ? "calc(9 / 12 * 100%)" : props.padding ? `calc(9 / 12 * 100% - ${props.padding} * 2)` : `calc(9 / 12 * 100% - ${vars.columnPadding} * 2)`};
`;

export const Col10 = styled(Col)`
width: ${props => props.padding === 0 ? "calc(10 / 12 * 100%)" : props.padding ? `calc(10 / 12 * 100% - ${props.padding} * 2)` : `calc(10 / 12 * 100% - ${vars.columnPadding} * 2)`};
`;

export const Col12 = styled(Col)`
width: ${props => props.padding === 0 ? "calc(12 / 12 * 100%)" : props.padding ? `calc(12 / 12 * 100% - ${props.padding} * 2)` : `calc(12 / 12 * 100% - ${vars.columnPadding} * 2)`};
`;

export const Text = styled.span.attrs(props => ({
    style: {
        padding: props.padding && props.padding,
        margin: props.margin && props.margin,
        display: props.flex && "flex",
        color: props.color && props.color,
    }
}))`
    font-size: ${vars.fontSizeBase};
`;

export const TextStrong = styled(Text)`
    font-weight: 500;
`;

export const TextSm = styled(Text)`
    font-size: ${vars.fontSizeSm};
`;

export const TextXs = styled(Text)`
    font-size: ${vars.fontSizeXs};
`;


export const ProgressBar = styled.div`
    height: 1.2rem;
    background: white;
    border-radius: 3px;
    width: 100%;
    overflow: hidden;

        div{
            background: ${vars.progressColor};
            width: ${props => props.width ? `${props.width}%` : 0};
            height: 100%;
            ${vars.flex_j_center}
        }
`;

// export const SelectBox = styled.div`
//         width: 160px;
//         height: 35px;
//         background: url('/img/triangle-w.svg') no-repeat right;
//         background-size: 13px 10px;
//         border: 1px solid black;
//         overflow: hidden;
// `;
export const Select = styled.select`
    appearance: none;
    color: white;
    width: 150px;
    height: 1.7rem;
    border: 0;
    background: url('/img/triangle-w.svg') no-repeat right;
    background-size: 10px 10px;
    background-color: black;
   
    &:hover{
        cursor: pointer;
    }
    `;
