import { FMColumnWidth } from "../style/Variable";
//store for filemanager column resizer

/**
 * column resizer of filemanager
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */


const resizeColumn = {
    resize: false,
    name: "",
    "File Manager": {
        target: "",
        sibling: "",
        siblingInfo: "",
        listX: 0,
        listWidth: 0,
        rectWidth: 0,
        types: { "Name": { width: FMColumnWidth }, "Size": { width: FMColumnWidth }, "Form": { width: FMColumnWidth }, "Date": { width: FMColumnWidth } }
    },
    "All Apps": {
        target: "",
        sibling: "",
        siblingInfo: "",
        listX: 0,
        listWidth: 0,
        rectWidth: 0,
        types: { "Name": { width: FMColumnWidth }, "Size": { width: FMColumnWidth }, "Form": { width: FMColumnWidth }, "Date": { width: FMColumnWidth } }
    },
    //name: "",
    //sibling: "",
    //siblingInfo: "",
    //listX: 0,
    //listWidth: 0,
    //rectWidth: 0,
    //types: { "Name": { width: FMColumnWidth }, "Size": { width: FMColumnWidth }, "Form": { width: FMColumnWidth }, "Date": { width: FMColumnWidth } }
}

//name: triggered modal name
//target: triggered column name
//sibling: next sibling element name
//listX : rectangle(container) x position where the start
//rect width : total width of the rectangle
export default function columnResizer(state = resizeColumn, action) {
    switch (action.type) {
        case "resizeColumn/start":
            return {
                ...state,
                resize: true,
                name: action.name,
                [action.name]: {            //the name of the modal
                    ...state[action.name],
                    target: action.target, //the name of the column
                    sibling: action.sibling,
                    siblingInfo: action.siblingInfo,
                    listX: action.listX,
                    listWidth: action.listWidth,
                    rectWidth: action.rectWidth,
                },
                // clickedDiv: action.clickedDiv
            }
        case "resizeColumn/end":
            return {
                ...state,
                resize: false,
                target: "",
            }
        case "resizeColumn/update":
            // console.log(action.name, action.width, action.distance, action.sibling)
            return {
                ...state,
                // distance: action.distance,
                // rectWidth: action.rectWidth,
                // listX: action.listX,
                // clickedDiv: "",
                [action.name]: {
                    ...state[action.name],
                    types: {
                        ...state[action.name].types,
                        //[action.name]: {
                        //    ...state[action.name],
                        //    width: action.width
                        //},
                        [action.target]: {
                            width: action.width
                        },
                        [action.sibling]: {
                            width: action.siblingWidth
                        }
                    }

                }
                // types: { ...state.types, [action.name]: { width: action.width}}
            }
        default: return { ...state };
    }
}

//types: { ...state.types, [action.name]: { width: action.width }, [action.sibling]: { width: action.siblingWidth } }