// Copyright(c) CLEW Inc. All rights reserved.
//
// Writter: Lee, Jihye
// Last Modified: 2021.08.24
//
// Dependency:
//  - styled-component
//
// Description
// - Finestra Landing Page for mobile which does not support

import React, { Component } from 'react';
import { userManager } from './oidcClient';
import styled from 'styled-components';
import { IconLg, P, Img, A, IconFullCover} from './style/Component';

export default class NotFound extends Component {

    constructor(props) {
        super(props);
        this.p_2 = {
            padding: "2rem"
        };

        this.mt_3 = {
            marginTop: "3rem"
        };

        this.mt_4 = {
            marginTop: "4rem"
        };

        this.justifyContentCenter = {
            justifyContent: "flex-start"
        };

        this.text_bold = {
            fontWeight: "bold",
        };

    }

    render() {
        return (
            <Container>
                <FullWidth_Column>
                    <FullWidth>
                        <IconContainer>
                            <A href="/">
                                <Icon img={"./img/logo-geneers-dark.svg"} ></Icon>
                            </A>
                        </IconContainer>
                    </FullWidth>

                    {/*
                    <FullWidth style={Object.assign(this.justifyContentCenter, this.mt_3)}>
                        <Icon img={"./img/finestraText-c.svg"}></Icon>
                    </FullWidth>
                    */}

                    <FullWidth style={this.mt_3}>
                        <Img src={"./img/notsupportedbymobile.svg"} width={"60%"} >
                        </Img>
                    </FullWidth>

                    <h5 style={this.mt_3}>404 Error</h5>
                    <h5>Page Not Found</h5>
                    <P >The page you are looking for doesn't exist.</P>


                </FullWidth_Column>


            </Container>
        );
    }
}


const DisplayFlexCenterAll = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
`;
const Container = styled(DisplayFlexCenterAll)`
    flex-direction: column;
    padding: 1rem;
    background-color: white;
    width: calc(100vw - 2rem);
    height: calc(100vh - 2rem);
    justify-content: space-between;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
`;


const FullWidth = styled(DisplayFlexCenterAll)`
    width:100%;
    display: flex;
    align-items: center;
`;

const FullWidth_Column = styled(FullWidth)`
    flex-direction: column;
    
`;


const IconContainer = styled.div`
    margin-right: auto;
    width: 160px;
    height: 60px;

 `;


const Icon = styled.i.attrs(props => ({
    style: {
        backgroundImage: `url(${props.img})`,
    }
}))`
height: 60px;
background-size: contain;
display: inline-block;
background-repeat: no-repeat;
background-position: center;
width: 100%;
`;

const Icon_Conditional = styled(IconFullCover)`

    @media only screen and (min-width: 768px){
        background-image: url("${props => props.img_md}") !important;
    }

`;