import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { LinkableText, Modal, Row, } from "../../../../style/Component";

export default function Ctxtmenu(props) {
    const name = props.name;
    const modal = useSelector(state => state.modal);
    const bgIcon = useSelector(state => state.bgIcon);
    const dispatch = useDispatch();
    const focusedIcon = props.focusedIcon;
    const focusedIconName = focusedIcon.name;
    const target = focusedIcon.target;
    const icon = focusedIcon.icon;
    const mousePosition = props.mousePosition;
    //const File_Manager = "File Manager";
    
    const setBackgroundIconHandler = e => {
        e.preventDefault();
        //e.stopPropagation();
        //if the icon is already exist on bg => return nothing
        const isExist = bgIcon.filter(icon => icon.name === focusedIcon.name)
        if (isExist.length > 0) {
            return;
        }


        if (target === "modal") {
            // check if the icon already exist on bg
            // console.log(focusedIcon)
            // const exist = bgIcon.filter( icon => icon.focusedIconName === focusedIconName).length > 0;
            // if(bgIcon.filter( icon => icon.focusedIconName === focusedIconName).length > 0){
            //     focusedIconName = `${focusedIconName}(${exist})`;
            // }
            const width = focusedIcon.width;
            const height = focusedIcon.height;
            dispatch({ type: "bgIcons/modal/update", name: focusedIconName, icon, width, height, date: new Date().toLocaleString() });
            /*} else if (type === "web") {*/
        } else {
            const url = focusedIcon.url;
            dispatch({ type: "bgIcons/web/update", name: focusedIconName, icon, url, date: new Date().toLocaleString() });
        }

        dispatch({ type: "ctxtmenu/all/deactivate" });

    }

    const actModalHandler = e => {
        e.preventDefault();
        e.stopPropagation();

        if (target === "modal") {
            const width = focusedIcon.width;
            const height = focusedIcon.height;

            //if exist => add
            if (modal[focusedIconName]) {
                // if (typeof modal[focusedIconName] !== undefined && !modal[focusedIconName].active) {
                if (typeof modal[focusedIconName] !== undefined) {
                    dispatch({ type: "modal/activate", name: focusedIconName });
                    //commented because activate and zIndex actually works in same say
                    //if modal is already working, set zIndex higher of the modal
                    // }else if (typeof modal[focusedIconName] !== undefined && modal[focusedIconName].active) {
                    // dispatch({ type: "modal/zIndex", focusedIconName })
                }
                //if doesn't exist => update
            } else {
                const y = Math.random() * (150 - 100) + 100;
                const x = Math.random() * (350 - 300) + 300;

                dispatch({ type: "modal/update", name: focusedIconName, icon, x, y, width, height });
            }

            dispatch({ type: "statusbar/update", name: focusedIconName, icon });
            dispatch({ type: "current/active", name: focusedIconName })


        } else {
            const url = focusedIcon.url;
            const target = focusedIcon.target;
            window.open(url, target);
        }

        dispatch({ type: "ctxtmenu/all/deactivate" });

    }

    return (
        <Modal x={mousePosition[0]} y={mousePosition[1]} zIndex={modal[name].zIndex + 1} >
            <Row bg={"white"} padding={0} start={"true"}>
                <LinkableText width={"100%"} padding={".5rem 1rem"} onClick={setBackgroundIconHandler}> Add to Background </LinkableText>
                <LinkableText width={"100%"} padding={".5rem 1rem"} onClick={actModalHandler}> Run </LinkableText>
            </Row>
        </Modal>
    )
}