import React from 'react';
import { useState, useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconXs, Li, StatusbarButton, IconXxs, TextSm, Col7, Row, Screwbar, ModalSttNamebar, } from "../../style/Component";
import { modalHeight, Icon_close_w, modalSttbarColor, modalSttbarCurrentColor, modalSttbarListWidth, Icon_maximize_w, Icon_minify_w, Icon_backToOrigin_w } from "../../style/Variable";

export default function ModalSttbar(props) {
    const MIN = "min";
    const MAX = "max";
    const CLOSE = "close";
    const ORIGIN = "origin";
    const modalRef = props.modal;

    const dispatch = useDispatch();
    let prevSize = useRef({ x: 0, y: 0, width: 0, height: 0 });
    const current = useSelector(state => state.current);
    const modal = useSelector(state => state.modal);
    let defSttbarIcons = [{ name: MIN, img: Icon_minify_w }, { name: MAX, img: Icon_maximize_w }, { name: CLOSE, img: Icon_close_w }];
    const [sttbarIcons, setSttbarIcons] = useState(defSttbarIcons);
    const name = props.name;
    const attr = modal[name];

    //useEffect(() => {

    //    if (/iPad/i.test(navigator.userAgent)) {
    //    }
    //});

    const modalHandler = (e, target) => {
        e.preventDefault();
        e.stopPropagation();

        switch (target) {
            case MIN:
                dispatch({ type: "modal/hide", name });
                dispatch({ type: "current/reset" });
                break;
            case MAX:
                //set previous modal size
                prevSize.current = { width: attr.width, height: attr.height, x: attr.x, y: attr.y };
                setSttbarIcons(state => {
                    const update = { name: ORIGIN, img: Icon_backToOrigin_w };
                    const newState = state;
                    newState[1] = update;
                    return newState;
                })
                dispatch({ type: "modal/maximize", name });
                dispatch({ type: "current/active", name });
                break;
            case ORIGIN:
                setSttbarIcons(state => {
                    const update = { name: MAX, img: Icon_maximize_w };
                    const newState = state;
                    newState[1] = update;
                    return newState;
                })
                //set modal size with use previous size
                const position = {
                    x: prevSize.current.x,
                    y: prevSize.current.y
                };

                dispatch({ type: "modal/update", name, width: prevSize.current.width, height: prevSize.current.height, x: position.x, y: position.y });
                dispatch({ type: "current/active", name });
                break;
            case CLOSE:
                dispatch({ type: "modal/deactivate", name });
                dispatch({ type: "statusbar/deactivate", name });
                dispatch({ type: "current/reset" });
                break;
            default:
                console.warn('You must not read this message. wtf')
                break;
        }

        //deactivate opened statusbar modal
        dispatch({ type: "staticIcon/deactivate" })
        dispatch({ type: "hamburger/deactivate" });
        dispatch({ type: "staticBtn/all/deactivate" });
        dispatch({ type: "ctxtmenu/all/deactivate" });
    }


    const handlePreventDrag = e => {
        e.stopPropagation();
        e.dataTransfer.effectAllowed = "none";
    }


    const dragStartHandler = e => {
        //console.log('event fired!!!');
        e.preventDefault();
        e.stopPropagation();

        // const dragStartHandler = e => {
        //     e.preventDefault();
        //     e.stopPropagation();
        //e.dataTransfer.setData('text/plain', name);
        // is for non-image div when mouse drags content div
        //if (e.target !== modalRef.current) {
        //    e.dataTransfer.effectAllowed = "none";
        //    e.dataTransfer.setDragImage(dNonRef.current, 0, 0);
        //    return;
        //}

        //setTimeout(() => modalRef.current.style.top = "-10000px", 0);

        let mousePositionOnModal = {
            x: e.nativeEvent.layerX,
            y: e.nativeEvent.layerY
        };

        //setTimeout(() => modalRef.current.style.display = "none", 0);

        //e.dataTransfer.effectAllowed = "copy";

        //const clonedModal = document.createElement('div');
        //clonedModal.id = "drag-ghost";
        //clonedModal.style.position = "absolute";
        //clonedModal.style.top = "-1000px";
        //clonedModal.style.width = `${attr.width}px`;
        ////clonedModal.style.height = `${attr.height}px`;
        //clonedModal.style.background = "red";

        //const statusbar = document.createElement('div');
        //statusbar.style.width = "80px";
        //statusbar.style.height = "150px";
        //statusbar.style.display = "flex";
        //statusbar.style.justifyContent = "space-between";
        //const span = document.createElement('span');
        //span.innerText = props.name;
        //const span2 = document.createElement('span');
        //span2.innerText = "close";

        //statusbar.appendChild(span);
        //statusbar.appendChild(span2);
        //clonedModal.appendChild(statusbar);


        //modalRef.current.appendChild(clonedModal);

        //e.dataTransfer.setDragImage(clonedModal, 0, 0);

        dispatch({ type: "current/active", name });
        dispatch({ type: "modal/activate", name });
        dispatch({ type: "draggable/start", name, target: modalRef.current, mousePosition: mousePositionOnModal });

    }



    return (
        <Row padding={0} bg={current.name === name ? modalSttbarCurrentColor : modalSttbarColor} between={true} height={`${modalHeight}px`}
            onMouseDown={dragStartHandler} 
            onDoubleClickCapture={e => (sttbarIcons[1].name === MAX) ? modalHandler(e, MAX) : modalHandler(e, ORIGIN)}>
            <ModalSttNamebar align={"center"} >
                &nbsp;<IconXs img={attr.icon}></IconXs>
                <TextSm color={"white"}>&nbsp;&nbsp;{name}</TextSm>
            </ModalSttNamebar>
            <Screwbar onMouseDown={e => { e.preventDefault(); e.stopPropagation(); } }>
                {sttbarIcons.map(icon => {
                    return (
                        <Li width={modalSttbarListWidth} key={icon.name}>
                            <StatusbarButton isScrewbar={true} onClick={e => modalHandler(e, icon.name)} >
                                <IconXxs img={icon.img} ></IconXxs>
                            </StatusbarButton>
                        </Li>
                    )
                })}
            </Screwbar>
        </Row>
    )
}