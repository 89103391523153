import React, { Component } from 'react';
import { UserManager } from 'oidc-client';
import styled, { keyframes } from 'styled-components';


export default class Callback extends Component {
    constructor(props) {
        super(props);
    }

    /**
    * callback function for oidc to get data and go back to the previous page
    */
    componentDidMount = () => {
        const userManager = new UserManager({
            loadUserInfo: true, filterProtocolClaims: true, response_mode: "query"
        });

        userManager.signinRedirectCallback().then(function (user) {
            if (user) {
                window.history.replaceState({},
                    window.document.title,
                    window.location.origin + window.location.pathname);
                    //window.location.origin + "/login");
                //console.log(window.location.origin)
                window.location = "/";
            } else {
                console.log('%cTHERE IS NO USER', "font-weight: 600; color: red;");
            }
        }, function (error) {
                console.log('%cTHERE IS AN ERROR', error, "font-weight: 600; color: red;");
        });
    };

    render() {
        return (
            <Div>
                <Loading_Box>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </Loading_Box>
            </Div>
        );
    }
}
//export default withRouter(Callback);

const Div = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
`;


const load = keyframes`
     0%{
        height: 0px;
        transform: translate( 0, 6px);
        background: linear-gradient(45deg, white , black );
    }
    20%{
        height: 0px;
        transform: translate( 0, 6px);
        
    }
    80%{
        height: 6px;
        transform: translate( 0,  0px);
    }
    100%{
        height: 6px;
        transform: translate( 0,  0px);
        background: linear-gradient(45deg, black, black );
    }
`;

const Loading_Box = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 80px;
    height: 90px;

        div{
            width: 35px;
            height: 6px;
            background: linear-gradient(45deg, white, black );
            animation: ${load} 1.5s infinite alternate-reverse;
            transform: translate( 0, 6px);
            &:nth-child(6){
                animation-delay: -.4s;
            }
            &:nth-child(5){
                animation-delay: -.34s;
            }
            &:nth-child(4){
                animation-delay: -.28s;
            }
            &:nth-child(3){
                animation-delay: -.22s;
            }
            &:nth-child(2){
                animation-delay: -.16s;
            }
            &:nth-child(1){
                animation-delay: -.1s;
            }
        }
`;