// Copyright(c) CLEW Inc. All rights reserved.
//
// Writter: Lee, Jihye
// Last Modified: 2021.08.24
//
// Dependency:
//  - styled-component
//
// Description
// - Finestra Lock Page: When user click the lock account button, it will show up



import React, { Component, useEffect, useState, useRef } from 'react';
//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
import { userManager } from './oidcClient';
import styled from 'styled-components';
import { IconFullCover, ColoredButton, IconSemi } from './style/Component';
import { gray_ececec, userInfoHeight, gray_cecece } from './style/Variable';
import store from './store/store';
import { useDispatch, useSelector } from 'react-redux';

export default function Lock() {

    const signUpPage= "https://id.geneers.com/Account/SignUp";
    const userInfo = useSelector(state => state.userInfo);
    const [password, setPassword] = useState("");
    const [errMsg, setErrMsg] = useState("");

    const pwdRef = useRef();
    const today = new Date();
    const hours = today.getHours() < 10 ? `0${today.getHours()}` : today.getHours();
    const minutes = today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes();
    const seconds = today.getSeconds() < 10 ? `0${today.getSeconds()}` : today.getSeconds();

    const [currentTime, setCurrentTime] = useState(`${hours} : ${minutes} : ${seconds}`);

    const dispatch = useDispatch();

    const mt_1 = {
        marginTop: ".3rem"
    };

    const text_danger = {
        color: "rgb(225, 64, 64)"
    };

    useEffect(() => {
        pwdRef.current.focus();
        function tick() {
            return setTimeout(() => {

                const current = new Date();
                const currentHours = current.getHours() < 10 ? `0${current.getHours()}` : current.getHours();
                const currentMinutes = current.getMinutes() < 10 ? `0${current.getMinutes()}` : current.getMinutes();
                const currentSeconds = current.getSeconds() < 10 ? `0${current.getSeconds()}` : current.getSeconds();

                setCurrentTime(`${currentHours} : ${currentMinutes} : ${currentSeconds}`);

            }, 1000);
        }

        tick();

        return () => clearTimeout(tick);

    },[currentTime]);

    const toSignUpPage = e => {
        e.preventDefault();
        window.open(this.state.signUpPage, '_blank');
    };

    const enterPasswordHandler = e => {
        //e.preventDefault();
        setPassword(e.target.value);
        console.log('%csubmiit triggered', "background-color: yellow; font-size: 2rem;");

        dispatch({ type: "isLock/deactive" });
        window.localStorage.setItem('lock', 'false');

    }

    const logoutHandler = e => {
        e.preventDefault();
        //e.stopPropagation();

        window.localStorage.setItem('lock', 'false');
        dispatch({ type: "isLock/deactive" });
        userManager.signoutRedirect();

    }

    

        return (
            <Container>
                <NavContainer>
                    <Nav>
                        <ImageContainer>
                            <IconFullCover img={'/img/account_man.png'} ></IconFullCover>
                        </ImageContainer>
                        <UserInfo>
                            <Text >{userInfo.name.length > 15 ? `${userInfo.name.substring(0, 14)}..` : userInfo.name}</Text>
                            <Text_Small >{userInfo.email}</Text_Small>
                        </UserInfo>
                        <Buttons >
                            <Button onClick={logoutHandler}>
                                <IconSemi img={"./img/logout-b.svg"} ></IconSemi>
                            </Button>
                        </Buttons>
                    </Nav>
                </NavContainer> 

                <Center>
                    <h1>{currentTime}</h1>
                    <DisplayFlexCenterAll>
                        <ImageContainer_sm>
                            <IconFullCover img={'/img/password-w.svg'} ></IconFullCover>
                        </ImageContainer_sm>

                        <Input_Password ref={pwdRef} type="password" placeholder="Password" value={password}
                            onChange={enterPasswordHandler}>
                        </Input_Password>
                    </DisplayFlexCenterAll>

                    { errMsg && <Text_Small style={Object.assign(mt_1, text_danger)}>{errMsg}</Text_Small> }
                    
                </Center>
            </Container>
        );
    }


const DisplayFlexCenterAll = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
    `;
const Container = styled(DisplayFlexCenterAll)`
        width: 100vw;
        height: 100vh;
        background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('/img/space.jpg') center center/cover no-repeat;
    `

const Nav = styled(DisplayFlexCenterAll)`
        width: calc(100% - 3rem);
        height: calc(100% - 2rem);
        display: flex;
        padding: 1rem 1.5rem;                                                                                   
    `;

const NavContainer = styled.nav`
        position: absolute;
        right: 0;
        top: 0;
        width: 320px;
        height: 88px;
    `;

const ImageContainer = styled.div`
        min-width: 50px;
        width: 50px;
        height: 50px;
        border: 3px solid white;
        border-radius: 50%;
        overflow: hidden;
    `;

const ImageContainer_sm = styled(ImageContainer)`
        min-width: 22px;
        width: 22px;
        height: 22px;
        border: 0;
        overflow: hidden;
    `;

const UserInfo = styled.div`
        padding-left: 1rem;
        display: flex;
        flex-direction: column;
    `;

const Buttons = styled.div`
        margin-left: auto;
        margin-right: 0;
    `;

const Text = styled.span`
        font-weight: 500;
        color: white;
        font-size: .9rem;
    `;

const Text_Small = styled(Text)`
        font-size: .75rem;
    `;

const Button = styled.button`
        background-color: ${gray_ececec};
        border: medium none ${gray_ececec};
        border-radius: 2px;
        color: white;
        padding: .4rem;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover{
            cursor: pointer;
        }
    `;
const Center = styled(DisplayFlexCenterAll)`
        width: 100%;
        margin: auto 0;
        color: white;
        flex-direction: column;
        h1{
            font-size: 8rem;
        }
    `;

const Input_Password = styled.input`
    background: rgba(0, 0, 0, 0);
    border: 0;
    outline: 0;
    border-bottom: 1px solid white;
    font-size: 1.25rem;
    padding: .5rem;
    color: white;
    width: 14rem;
`