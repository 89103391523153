const defApps = [
    //{ name: "Search", target: "modal", icon: '/img/search-w.svg', width: "560", height: "508" },

    { name: "File Manager", target: "modal", icon: '/img/filemanager-c.svg', width: "960", height: "596", form: "folder", date: new Date().toLocaleString() },
    { name: "All Apps", target: "modal", icon: '/img/applibrary-c.svg', width: "960", height: "436", form: "application", date: new Date().toLocaleString() },

    //{ name: "Account", target: "modal", icon: '/img/account-w.svg', width: "560", height: "596", form: "application", date: new Date().toLocaleString() },
    //{ name: "Setting", target: "modal", icon: '/img/setting-c.png', width: "560", height: "436", form: "application", date: new Date().toLocaleString() },
    //{ name: "Job Manager", target: "modal", icon: '/img/jobmanager-c.svg', width: "609", height: "731.2", form: "application", date: new Date().toLocaleString() },

    { name: "Store", target: "modal", icon: '/img/store-c.svg', width: "785", height: "836", form: "application", date: new Date().toLocaleString() },
    { name: "IE4.IMCA", target: "_blank", icon: "/img/G-LOGO-v3.svg", url: "https://imca.geneers.com", form: "program", date: new Date().toLocaleString() },
    { name: "IE4.LSPM", target: "_blank", icon: "/img/G-LSPM-LOGO.svg", url: "https://lspm.geneers.com", form: "program", date: new Date().toLocaleString() },
    { name: "IE4.LFX", target: "_blank", icon: "/img/imca_fem_logo_color.svg", url: "https://lfx.geneers.com", form: "program", date: new Date().toLocaleString() },
    { name: "IE4.EMF", target: "_blank", icon: "/img/imca_fem_logo_color.svg", url: "https://emf.geneers.com", form: "program", date: new Date().toLocaleString() },
];

export default function allApps(state = defApps, action) {

    switch (action.type) {
        case "allApps/modal/update":
            return [
                ...state, { name: action.name, target: "modal", icon: action.icon, width: action.width, height: action.height, date: action.date }
            ]

        case "allApps/web/update":
            return [
                ...state, { name: action.name, target: "_blank", icon: action.icon, url: action.url, date: action.date }
            ];

        default:
            return state;
    }
}

