//store for filemanager directory width resizer

import { FMDirectoryWidth } from "../style/Variable"

const modalResizerWidth = { 
    resize: false,
    target: "",
    "File Manager": {
        resize: false,
        x: 0,
        width: FMDirectoryWidth
    },
    "All Apps": {
        resize: false,
        x: 0,
        width: FMDirectoryWidth
    }
    // resize: false,
    // target: "",
    // x: 0,
    // width: FMDirectoryWidth
}


/**
 * file manager left directory bar resizing information
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */

export default function widthResizer( state = modalResizerWidth, action ){
    switch(action.type){
        case "resizeWidth/start":
            return {
                ...state, resize: true, target: action.name, [action.name]: { ...state[action.name], resize: true, x: action.x}
            }
        case "resizeWidth/end":
            return {
                ...state,  resize: false, target: "", [action.name]: { ...state[action.name], resize: false }
            }
        case "resizeWidth/update":
            return {
                ...state, [action.name]: { ...state[action.name], width: action.width }
            }
        default: return { ...state }
    }
}

