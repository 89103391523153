import { StatusbarButton, Icon, IconActiveDiv, Li } from '../../../style/Component';
// Modal
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';


export default function ActiveItems(props) {
    const dispatch = useDispatch();
    const current = useSelector(state => state.current);
    const name = props.name;
    const info = props.info;

    const iconClickHandler = e => {
        e.preventDefault();
        e.stopPropagation();

        if (current.name === name) {
            dispatch({ type: "modal/hide", name });
            dispatch({ type: "current/reset" });
        } else {
            dispatch({ type: "modal/activate", name });
            dispatch({ type: "current/active", name });
        }


        //deactivate opened statusbar modal
        dispatch({ type: "staticIcon/deactivate" });
        dispatch({ type: "hamburger/deactivate" });
        dispatch({ type: "staticBtn/all/deactivate" });
        dispatch({ type: "ctxtmenu/all/deactivate" });
    };

    return (
        <Li >
            <StatusbarButton onClick={iconClickHandler} current={current.name === name} title={name}>
                <Icon img={info.icon}></Icon>
                <IconActiveDiv show={info.active} ></IconActiveDiv>
            </StatusbarButton>
        </Li>
    )
}
