
/**
 * currently activated modal information
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */

export default function current(state = { name: "" }, action){
    switch(action.type){
        case "current/active":
            // console.log('from current store', action.name)
            return { ...state, name: action.name };
        case "current/reset":
            return { ...state, name: "" };
        default:
            return state;
    }
};
