import React from 'react';
import { useSelector } from 'react-redux';
import { Ul } from '../../../style/Component';
import { navTop_height } from '../../../style/Variable';
import ActiveItems from './activeItems';

export default function Activebar(){
    // const modal = useSelector(state => state.modal);
    const statusbar = useSelector(state => state.statusbar);
    // Object.entries(statusbar).
    return(
        <Ul height={navTop_height}>
            {
                Object.keys(statusbar).length > 0 && Object.entries(statusbar).map(item => {
                    const name = item[0];
                    const info = item[1];
                    return (
                        <ActiveItems key={name} name={name} info={info}></ActiveItems>
                    )
                })
            }
        </Ul>
    );
}
