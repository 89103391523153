import {Icon_folder_c} from '../style/Variable';

// const bgIcons = [
//     // { name: "Search", target: "modal", icon: '/img/search-w.svg', width: "560", height: "508" },
//     { name: "File Manager", target: "modal", icon: '/img/filemanage-w.svg', width: "960", height: "596" },
//     // { name: "Account", target: "modal", icon: '/img/account-w.svg', width: "560", height: "436" },
//     // { name: "Setting", target: "modal", icon: '/img/set8-w.svg', width: "560", height: "436" },
//     { name: "Job Manager", target: "modal", icon: '/img/workmanager-b.svg', width: "609", height: "731.2" },
//     { name: "Store", target: "modal", icon: '/img/store-c.svg', width: "640", height: "836" },
//     { name: "App Collection", target: "modal", icon: '/img/applibrary-w.svg', width: "657", height: "518" },
//     { name: "IE4", target: "_blank", icon: "/img/G-LOGO-v3.svg", url: "https://ie4.clew.dev/" },
// ];

const bgIcons = [
    { name: "IE4.IMCA", icon: "/img/G-LOGO-v3.svg", target: '_blank', url: "https://imca.geneers.com", date: new Date().toLocaleString() },
    { name: "IE4.LSPM", target: "_blank", icon: "/img/G-LSPM-LOGO.svg", url: "https://lspm.geneers.com", form: "program", date: new Date().toLocaleString() },
    { name: "IE4.LFX", target: "_blank", icon: "/img/imca_fem_logo_color.svg", url: "https://lfx.geneers.com", form: "program", date: new Date().toLocaleString() },
    { name: "IE4.EMF", target: "_blank", icon: "/img/imca_fem_logo_color.svg", url: "https://emf.geneers.com", form: "program", date: new Date().toLocaleString() },
];

export default function bgIcon(state = bgIcons, action) {
    // const newState = state;

    switch (action.type) {
        case "bgIcons/modal/update":
            return [
                ...state, { name: action.name, target: "modal", type: "modal", icon: action.icon, width: action.width, height: action.height, date: action.date }
            ]
        // return {
        //     ...state, [action.name]: { name: action.name, target: "modal", icon: action.icon, width: action.width, height: action.height }
        // }


        case "bgIcons/web/update":
            return [
                ...state, { name: action.name, target: "_blank", type: "web", icon: action.icon, url: action.url, date: action.date }
            ];
        // return {
        //     ...state, [action.name]: { name: action.name, target: "_blank", icon: action.icon, url: action.url }
        // };
        case "bgIcons/folder/update":
            return [
                ...state, { name: action.name, target: "modal", type: "folder", icon: Icon_folder_c, width: action.width, height: action.height, date: action.date }
            ];

        case "bgIcons/deactivate":
            const newState = state;
            newState.filter(each => each.name !== action.name);
            return newState;
        // delete newState[action.name];
        // return newState;

        default:
            return state;
    }
}

