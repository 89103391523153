import React from 'react';
import { Card, Col12, Col3, Col9, IconFull, IconXs, Row, TextXs } from "../../../../style/Component";

export default function Cards(props) {
    const title = props.title;
    const info = props.info;
    const name = props.name;
    const type = props.type;
    const point = props.point / 0.5;
    const download = props.download;
    const img = props.img;


    return (
        <Card shadow={true} title={title}>
            <Col3 height={"100%"}>
                <IconFull img={img}></IconFull>
            </Col3>
            <Col9 column={true} between={true}>
                <Row>
                    <h6>{title.length < 30 ? title : `${title.substring(0, 30)}..`}</h6>
                    {/*<h6>{title}</h6>*/}
                    <p>{info.length < 65 ? info : `${info.substring(0, 65)}..`}</p> 
                    {/*<p>{info}</p>*/}
                </Row>
                <Row padding={0}>
                    <Col12 between={true} >
                        <span>{name}</span>
                        <span>{type}</span>
                    </Col12>
                    <Col12 between={true} >
                        <div >
                            {[...Array(Math.floor(point / 2))].map((each, index) =>
                                <IconXs key={index} img={"/img/star-color.svg"}></IconXs>
                            )}
                            {[...Array(point % 2)].map((each, index) =>
                                <IconXs key={index} img={"/img/star-color-half.svg"}></IconXs>
                            )}
                            {[...Array(5 - Math.floor(point / 2) - point % 2)].map((each, index) =>
                                <IconXs key={index} img={"/img/star-line.svg"}></IconXs>
                            )}
                        </div>
                        <TextXs>Download {download}</TextXs>
                    </Col12>
                </Row>
            </Col9>
        </Card>
    )
}