
/**
 * update user info from usermanager
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */

export default function userInfo(state = {}, action) {
    switch (action.type) {
        case "userInfo/update":

            return {
                ...state, name: action.name, email: action.email, nickname: action.nickName
            };
        case "userInfo/reset":
            return {};
        default:
            return state;
    }
};
