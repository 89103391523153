// [ { name:"", icon:"" } ],
// { name: { icon: "", active: true } }



/**
 * Statusbar active space information
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */

export default function statusbar ( state = {}, action){
    switch(action.type){
        // case "statusbar/activate":
        //     return { ...state, [action.name]: { icon: action.icon, show: true, active: true } };
        case "statusbar/update": 
            return { ...state, [action.name]: { icon: action.icon, show: true, active: true } };

        case "statusbar/deactivate":
            const newState = state;
            delete newState[action.name];
            return newState;
            // return { ...state, [action.name]: { ...state[action.name], show: false, active: false } };
        default:
            return state;
    }
}