import React from 'react';
import { useEffect, useState } from "react";
import { Container, Tab, Row, Ul, Li } from "../../../../style/Component"
import { gray_ececec } from "../../../../style/Variable"
import AllApps from "./allApps";
import Purchased from './purchased';

export default function Store() {
    const defaultMenus = { "All Apps": { focus: false, toggle: "allApps" }, "Purchased": { focus: false, toggle: "purchased" } }
    const [menus, setMenus] = useState(defaultMenus);
    const [content, setContent] = useState(<AllApps />);

    useEffect(() => {
        const firstItem = Object.entries(defaultMenus).filter((each, index) => index === 0)[0];
        const name = firstItem[0];
        const update = defaultMenus;
        update[name].focus = true;
        setMenus({ ...update });
    }, [])

    const tabViewHandler = (e, name) => {
        e.preventDefault();
        e.stopPropagation();
        
        const target = menus[name].toggle;

        const firstItem = Object.entries(defaultMenus).filter(each => each[0] === name)[0];
        const targetName = firstItem[0];
        const update = defaultMenus;
        update[targetName].focus = true;
        setMenus({ ...update });

        switch (target) {
            case "allApps": setContent(<AllApps />);
                break;
            case "purchased": setContent(<Purchased />)
                break;
            // case ""
            default: break;
        }
    };


    return (
        <Container bg={"white"}>
            <Row bg={gray_ececec} padding={0}>
                <Ul>
                    {Object.entries(menus).map(menu => {
                        const name = menu[0];
                        const attr = menu[1];
                        return (
                            <Li key={name} onClick={e => tabViewHandler(e, name)}>
                                <Tab isFocused={attr.focus} >
                                    {name}
                                </Tab>
                            </Li>
                        )
                    })}
                </Ul>
            </Row>
            {content}
        </Container>
    )
}