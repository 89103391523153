import React from 'react';
import { useState } from "react";
import { Select, ProgressBar, Row, Text, TextSm, Container, Col12, } from "../../../../style/Component";
import { WMTitleHeight, workManagerBg } from "../../../../style/Variable";

export default function Volume() {
    const [volume, setVolume] = useState("Finestra");
    // const [focus, setFocus] = useState(true);

    const volumeHandler = e => {
        e.preventDefault();
        e.stopPropagation();
        
        setVolume(e.target.value)
    }

    // const focusHandler = e => {
    //     e.preventDefault();
    //     setFocus(prev => !prev);
    // }

    return (
        <Container height={"max-content"} color={"white"}>
            <Row padding={WMTitleHeight} height={WMTitleHeight}>
                <Col12>
                    <Text>Volume</Text>
                    {/* <Button onClick={focusHandler}>
                    <DirectoryBar isFocused={focus}>
                    <div></div>
                    </DirectoryBar>
                </Button> */}
                </Col12 >
            </Row>
            <Row padding={WMTitleHeight} bg={workManagerBg} lightShadow={true} >
                <Col12 between={true} align={"center"}>
                    <Select value={volume} onChange={volumeHandler}>
                        <option value="Finestra">Finestra</option>
                        <option value="Other">Other</option>
                    </Select>
                    <TextSm>Available 3GB / Total 5GB</TextSm>
                </Col12>
                <Col12>
                    <ProgressBar width={40}><div><TextSm>40%</TextSm></div></ProgressBar>
                </Col12>
            </Row>
        </Container>
    )
}