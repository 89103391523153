
/**
 * restore if page has been locked or no
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */

export default function isLock(state = false, action) {
    switch (action.type) {
        case "isLock/active":
            return true;
        case "isLock/deactive":
            return false;
        default:
            return state;
    }
};
