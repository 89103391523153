import { createStore } from 'redux';
import { combineReducers } from 'redux';


import defSys from './defsystem';
import bgIcon from './bgIcon';
import modal from './modal';
import draggable from './draggable';
import current from './current';
import statusbar from './statusbar';
import modalResizer from './modalResizer';
import columnResizer from './columnResizer';
import widthResizer from './widthResizer';
import hamburger from './hamburger';
import staticBtn from './staticBtn';
import defBtn from './defBtnOfHamburger';
import ctxtmenu from './ctxtmenu';
import allApps from './allApps';
import isLock from './isLock';
import userInfo from './user';

const rootReducer = combineReducers({
    defSys,
    bgIcon,
    modal,
    draggable,
    current,
    statusbar,
    modalResizer,
    columnResizer,
    widthResizer,
    hamburger,
    staticBtn,
    defBtn,
    ctxtmenu,
    allApps,
    isLock,
    userInfo,
});

const store = createStore(rootReducer);

export default store;

