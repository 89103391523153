import React from 'react';
import { useState } from "react";
import { Img, Row, Select, Col6, Text, Col4, Col8, ProgressBar, TextSm, Container, Col12 } from "../../../../style/Component";
import { WMTitleHeight, workManagerBg } from "../../../../style/Variable";

export default function UsageRecord() {
    const [usage, setUsage] = useState("Geneers IMCA");
    // const [focus, setFocus] = useState(true);
    const status = [{ name: "Plan", status: "Full Package Solution" }, { name: "Use Time", status: "132h / 240h" }];

    const usageHandler = e => {
        e.preventDefault();
        e.stopPropagation();
        setUsage(e.target.value)
    }

    // const focusHandler = e => {
    //     e.preventDefault();
    //     setFocus(prev => !prev);
    // }

    return (
        <Container height={"max-content"} color={"white"}>
            <Row padding={WMTitleHeight} height={WMTitleHeight}>
                <Col12>
                    <Text>Usage Record</Text>
                    {/* <Button onClick={focusHandler}><DirectoryBar isFocused={focus}><div></div></DirectoryBar></Button> */}
                </Col12>
            </Row>
            <Row padding={WMTitleHeight} bg={workManagerBg} lightShadow={true} >
                <Col12 align={"center"}>
                    <Select value={usage} onChange={usageHandler}>
                        <option value="Geneers IMCA">Geneers IMCA</option>
                        <option value="Geneers LSPM">Geneers LSPM</option>
                    </Select>
                </Col12>
                <Col6>
                    <Img src="/img/graphbg-w.svg" width="100%"></Img>
                </Col6>
                <Col6 column={true}>
                    {status.map(each => (
                        <Row key={each.name}>
                            <Col4>
                                <span>{each.name}</span>
                            </Col4>
                            <Col8>
                                <span>{each.status}</span>
                            </Col8>
                        </Row>
                    ))}
                    <br></br>
                    <Row>
                        <ProgressBar width={40}><div><TextSm>40%</TextSm></div></ProgressBar>
                    </Row>
                </Col6>
            </Row>

        </Container>
    )
}