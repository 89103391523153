
let foregroundBase=0;
function getNextZIndex(){
    return foregroundBase++;
}


//items info that can be modal 
const modalItems = {
    // 'Search': { icon: '/img/search-w.svg', zIndex: '0', width: "560", height: "508", y: "70", x: "300", show: false, active: false},
    // 'File Manager': { icon: '/img/filemanage-w.svg', zIndex: '0', width: "960", height: "596", y: "70", x: "200", show: false, active: false},
    // 'Account': { icon: '/img/account-w.svg', zIndex: '0', width: "560", height: "436", y: "100", x: "300", show: false, active: false},
    // 'Setting': { icon: '/img/set8-w.svg', zIndex: '0', width: "560", height: "436", y: "100", x: "300", show: false, active: false},
    // 'Job Manager': { icon: '/img/workmanager-b.svg', zIndex: '0', width: "609", height: "731.2", y: "80", x: "300", show: false, active: false},
    // 'Store': { icon: '/img/store-c.svg', zIndex: '0', width: "640", height: "836", y: "80", x: "300", show: false, active: false},
    // 'App Collection': { icon: '/img/applibrary-w.svg', zIndex: '0', width: "657", height: "518", y: "30", x: "200", show: false, active: false},
};

/**
 * Store fore modal information that user dbclicked or clicked(only get fires after dbclicked)
 * For active property, when user trigger 'modal/hide' type, it filters to check on statusbar showing.
 * 'modal/update' : use this when you add new modal or update modal information, not when you activate modal on the screen
 * 'modal/activate' : use this when you activate the modal
 * 'modal/deactivate': attention when you use deactivate, you should not filter with active property.
 *                     and always need to set with current store as null or "" whatever it is false value.
 * 
 * properties: show => is showing on the screen, active: is working foreground && background
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export default function modal(state = modalItems, action) {
    
    const zIndex = getNextZIndex();

    switch (action.type) {
        case "modal/activate":
            return { ...state, [action.name]: { ...state[action.name], zIndex, show: true, active: true } };

        case "modal/hide":
            return { ...state, [action.name]: { ...state[action.name], zIndex, show: false, active: true } };

        case "modal/deactivate":
            // const newState = state;
            // delete newState[action.name];
            // return newState;
            return { ...state, [action.name]: { ...state[action.name], show: false, active: false } };

        case "modal/maximize":
            // const clientW = window.innerWidth;
            // const clientY = window.innerHeight;
            // const height = clientY < 768 ? "768" : "100%";
            // const width = clientW < 1200 ? "1200" : "100%";
            const height = "100%";
            const width = "100%";
            return { ...state, [action.name]: { ...state[action.name], zIndex, width, height, x:0, y:0 } };

        case "modal/position":
            return { ...state, [action.name]: { ...state[action.name], zIndex, x: action.x, y:action.y } };

        case "modal/size":
            return { ...state, [action.name]: { ...state[action.name], zIndex, width: action.width, height:action.height } };

        case "modal/update":
            const icon = state[action.name] ? state[action.name].icon : action.icon;

            return { ...state, [action.name]: { ...state[action.name], name: action.name, zIndex, icon, 
                x: action.x, y:action.y, width: action.width, height: action.height, show: true, active: true } };

        // case "modal/zIndex":
        //     return { ...state, [action.name]: { ...state[action.name], zIndex, show: true, active: true } };

        default:
            return { ...state };
    }
}


// const systemItems =[
//     { name: 'IE4', icon: "/img/G-LOGO-v3.svg", target: '_blank', url: "https://ie4.clew.dev/"  },
// ];

// export default function sysReducer( state = systemItems, action){
//     switch (action.type){
//         case "getAct":
//             return [ ...state ];
//         default:
//             return [ ...state ];
//     }
// }
