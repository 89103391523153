import React from 'react';
import { useRef } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BgIconContainer, BgIconButton, BgIconName, IconLg, Row, Container } from '../../style/Component';
import { navTop_height } from '../../style/Variable';

import ModalContainer from '../modal/modal';
import Ctxtmenu from './ctxtmenu';
import Lock from '../../Lock';

/**
 * Rendering background icon from redux default items of state.bgIcon.
 * So, this is an interface to activate modal && web programs.
 * effect: click to focus
 *         dbclick to activate modal
 * 
 */
export default function Background() {
    const dispatch = useDispatch();
    
    //selector trigger re-renderer so that component gets update by dispatching
    const bgIcon = useSelector(state => state.bgIcon);
    //const defSys = useSelector(state => state.defSys);
    const allApps = useSelector(state => state.allApps);
    const modal = useSelector(state => state.modal);
    const ctxtmenu = useSelector(state => state.ctxtmenu);
    const current = useSelector(state => state.current);
    
    //const zIndex = current.name ? modal[current.name].zIndex : 1;
    const [zIndex, setZIndex] = useState(1);
    const availible = { ...allApps };
    const [focus, setFocus] = useState('');
    const BACKGROUND = "Background"
    const [mousePosition, setMousePosition] = useState([]);


    let bgContainerRef = useRef("bgContainer");
    let bgIconContainerRef = useRef("bgIconContainer");
    // Object.entries(modal).forEach(each => console.log(each))

    //this is going to work like componentDidMount()
    useEffect(() => {
        current.name && setZIndex(modal[current.name].zIndex);
       
        //console.log('bgIcon: ', bgIcon, 'modal: ',modal)
        //can't do this inside of useEffect!! you can't call setState.
        //calculate which modal is having the most highest zIndex
        // const zIndexes = Object.entries(modal).map( each => { return {name: each[0], zIndex: each[1].zIndex}});
        // highModal.current = zIndexes.sort((a, b) => b.zIndex - a.zIndex )[0];
        // dispatch({type: "current/active", zIndex: highModal.current});
    })

    const iconFocusHandler = (e, name) => {
        e.preventDefault();
        e.stopPropagation();

        setFocus(name);
        dispatch({ type: "current/reset", });

        //this will update bgIcons which comes from selector, and because of that it is going to trigger re-renderer
        // dispatch({type: "updateDisplayIcon", name: "lalala", icon: "/img/account-w.svg"})
    }

    const iconBlurHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setFocus("");
    };

    /**
     * Activate modal
     * Modal item already have added from defSys ? RUN : ADD
     */
    const actModalHandler = (e, name, info) => {
        e.preventDefault();
        e.stopPropagation();

        //if exist => add
        if (modal[name]) {
            // if (typeof modal[name] !== undefined && !modal[name].active) {
            if (typeof modal[name] !== undefined) {
                dispatch({ type: "modal/activate", name });
                //commented because activate and zIndex actually works in same say
                //if modal is already working, set zIndex higher of the modal
                // }else if (typeof modal[name] !== undefined && modal[name].active) {
                // dispatch({ type: "modal/zIndex", name })
            }
            //if doesn't exist => update
        } else {
            const y = Math.random() * (150 - 100) + 100;
            const x = Math.random() * (350 - 300) + 300;
            dispatch({ type: "modal/update", name, icon: info.icon, x, y, width: info.width, height: info.height });
        }

        dispatch({ type: "statusbar/update", name, icon: info.icon });
        dispatch({ type: "current/active", name })
    };
    
    const actWebHandler = (e, info) => {
        e.preventDefault();
        e.stopPropagation();

        window.open(info.url, info.target);
    }


    const ctxtMenuHandler = e => {
        e.preventDefault();
        e.stopPropagation();


        dispatch({ type: "ctxtmenu/all/deactivate" });

        if (e.target !== bgContainerRef.current &&
            e.target !== bgIconContainerRef.current) {
            return;
        }

        const x = e.pageX;
        const y = e.pageY - navTop_height;
        const position = [x, y];
        setMousePosition(position);
        
        dispatch({ type: "ctxtmenu/update", name: BACKGROUND });

    }

    return (
        <Container ref={bgContainerRef} height={`calc(100% - ${navTop_height}px)`} onContextMenu={ctxtMenuHandler}>
            <Row visible={true} ref={bgIconContainerRef} >
                {
                    // Object.entries(bgIcons).map(icon => {
                    //     const attr = icon[1];
                    (bgIcon).map(icon => {
                        const attr = icon;
                        const name = attr.name;
                        const imgPath = attr.icon;
                        const target = attr.target;
                        const isModal = target === "modal";
                        const info = isModal ? { icon: imgPath, width: attr.width, height: attr.height } : { url: attr.url, target: attr.target };
                        // const show = icon[1].show;
                        // const m = modal[name] ? modal[name].show && modal[name].active : false;
                        const isFocused = name === focus;
                        return (
                            // <BgIconContainer key={name} show={show} onClick={e => iconFocusHandler(e, name)} onBlur={iconBlurHandler}
                            <BgIconContainer key={name} onClick={e => iconFocusHandler(e, name)} onBlur={iconBlurHandler}
                                onDoubleClick={e => isModal ? actModalHandler(e, name, info) : actWebHandler(e, info)}>
                                <BgIconButton isColumn={true}>
                                    <IconLg img={imgPath}></IconLg>
                                    <BgIconName isFocused={isFocused && true}>{isFocused ? name : name.length > 9 ? `${name.substring(0, 8)}...` : name}</BgIconName>
                                </BgIconButton>
                                {/* {
                                m && (<ModalContainer name={name}></ModalContainer>)
                            } */}
                            </BgIconContainer>

                        )
                    })

                }
            </Row>

            {
                Object.entries(availible).map((icon, index) => {
                    const attr = icon[1];
                    const name = attr.name;
                    const m = modal[name] ? modal[name].show && modal[name].active : false;
                    return (
                        m && (<ModalContainer name={name} key={index}></ModalContainer>)
                    )
                })
            }

            { ctxtmenu[BACKGROUND] ? ctxtmenu[BACKGROUND].show
                && <Ctxtmenu mousePosition={mousePosition} bgIcon={bgIcon} zIndex={zIndex+1}></Ctxtmenu> : null}

        </Container>
    );
}
