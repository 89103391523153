import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LinkableText, Modal, Row } from "../../style/Component";
import { RANDOM_NAME } from "../../style/Variable";


export default function Ctxtmenu(props) {
    const modal = useSelector(state => state.modal);
    const bgIcon = props.bgIcon;
    const dispatch = useDispatch();
    const zIndex = props.zIndex;
    // const focusedIcon = props.focusedIcon;
    const mousePosition = props.mousePosition;

    useEffect(() => {
        // console.log(lists)
        // setLists(bgIcon);
    })

    const folderHandler = e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch({ type: "ctxtmenu/all/deactivate" });

        //add list
        const update = bgIcon;
        let name = "";

        //if you use a variable for the regex expression, you should call the class.
        //for the special characters, use double '\'
        //for the regex expression, use double '\'
        //'$' is for end of the line
        const getRandomNamedName = new RegExp(`${RANDOM_NAME}\\(\\d+\\)$`, "");
        const getInt = /\d+/;
        //numbers to collect previous listed name
        let numbers = [];

        for (let i = 0; i < update.length; i++) {
            //check if current list has listed name
            const result = getRandomNamedName.exec(update[i].name);
            //if it does, add the number
            if (result !== null) {
                const num = getInt.exec(result[0])[0];
                numbers.push(parseInt(num));
            }
        }

        //if there is any listed name list, compute next list number
        if (numbers.length > 0) {
            let gotcha = 0;
            const compareWith = [];
            //sort arrayed original data
            numbers = numbers.sort((a, b) => a - b);
            //make a comparable array from 1 to length + 1
            for (let i = 1; i < numbers.length + 1; i++) {
                compareWith.push(i);
            }

            //compare the comparable array with the sorted original data
            for (let i = 0; i < numbers.length; i++) {
                //if the data does not match => get the data
                if (compareWith[i] !== numbers[i]) {
                    gotcha = compareWith[i];
                    break;
                }
            }

            //if the variable stays still 0 which means the comparable array and the sorted original data have exact same data
            //so, just put the next number of it.
            if (gotcha === 0) {
                gotcha = compareWith.length + 1;
            }

            name = `${RANDOM_NAME}(${gotcha})`;

        } else {
            name = `${RANDOM_NAME}(${1})`;
        }

        //update next numbered list
        //    update.push(add);
        //    setLists(update);

        dispatch({ type: "bgIcons/folder/update", name, width: 500, height: 500, date: new Date().toLocaleString() });
    }

  

    return (
        <Modal x={mousePosition[0]} y={mousePosition[1]} zIndex={zIndex} >
            <Row bg={"white"} padding={0} start={"true"}>
                {/*<LinkableText width={"100%"} padding={".5rem 1rem"} onClick={folderHandler}> Create a folder </LinkableText>*/}
                {/*<LinkableText width={"100%"} padding={".5rem 1rem"} > WHAT </LinkableText>*/}
            </Row>
        </Modal>
    )
}