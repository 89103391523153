import React from 'react';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, } from "react-redux";
import styled from "styled-components";
import { Col8, ColoredButton, FlexibleWidth, IconSemi, Input, LinkableText, MlAuto, Col4, Row, UnLinkableText, Ul, Li, } from "../../../../style/Component";
import { gray_ececec, icon_edit_b, RANDOM_NAME, Icon_plus_b, icon_remove_b, red_pastel_light, icon_check_b, } from "../../../../style/Variable";

export default function Menu(props) {
    const show = props.show;
    const width = props.width;
    const updateTitle = props.updateTitle;
    const updateApps = props.updateApps;
    const container = useRef();
    const input = useRef([]);
    const [editName, setEditName] = useState("");
    const dispatch = useDispatch();
    const defSys = useSelector(state => state.defSys);
    const [lists, setLists] = useState([{ title: "All Apps", apps: { ...defSys } }, { title: `${RANDOM_NAME}(2)`, apps: {} }]);


    const getFalsyArray = () => {
        return lists.length !== 0 ? new Array(lists.length).fill(false) : [];
    }

    const [isListClicked, setIsListClicked] = useState(getFalsyArray().fill(true, 0, 1));
    const [isListHovered, setIsListHovered] = useState(getFalsyArray());
    const [activatedEdit, setActivatedEdit] = useState(getFalsyArray());

    /**Should I make this width as flexible or no? */
    const widthHandler = e => {
        e.preventDefault();
        const name = "App Collection";
        const containerInfo = container.current.getBoundingClientRect();
        const x = containerInfo.x;

        dispatch({ type: "resizeWidth/start", name, x })
    }

    useEffect(() => {
        if (isListClicked.length > 0) {
            const focusedListIndex = isListClicked.findIndex(each => each === true);
            // console.log(isListClicked,focusedListIndex, lists)
            // console.log(isListClicked) 
            if (focusedListIndex >= 0) {
                updateApps(lists[focusedListIndex].apps);
                updateTitle(lists[focusedListIndex].title);
            }
        }

    });

    /** update additional list on menu */
    const addListHandler = e => {
        e.preventDefault();
        e.stopPropagation();

        
        //if any list was activated to edit, make it false
        let activated = getFalsyArray();
        setActivatedEdit(activated);

        //add list
        const update = lists;
        let add = {};

        //if you use a variable for the regex expression, you should call the class.
        //for the special characters, use double '\'
        //for the regex expression, use double '\'
        //'$' is for end of the line
        const getRandomNamedName = new RegExp(`${RANDOM_NAME}\\(\\d+\\)$`, "");
        const getInt = /\d+/;
        //numbers to collect previous listed name
        let numbers = [];

        for (let i = 0; i < update.length; i++) {
            //check if current list has listed name
            const result = getRandomNamedName.exec(update[i].title);
            //if it does, add the number
            if (result !== null) {
                const num = getInt.exec(result[0])[0];
                numbers.push(parseInt(num));
            }
        }

        //if there is any listed name list, compute next list number
        if (numbers.length > 0) {
            let gotcha = 0;
            const compareWith = [];
            //sort arrayed original data
            numbers = numbers.sort((a, b) => a - b);
            //make a comparable array from 1 to length + 1
            for (let i = 1; i < numbers.length + 1; i++) {
                compareWith.push(i);
            }

            //compare the comparable array with the sorted original data
            for (let i = 0; i < numbers.length; i++) {
                //if the data does not match => get the data
                if (compareWith[i] !== numbers[i]) {
                    gotcha = compareWith[i];
                    break;
                }
            }

            //if the variable stays still 0 which means the comparable array and the sorted original data have exact same data
            //so, just put the next number of it.
            if (gotcha === 0) {
                gotcha = compareWith.length + 1;
            }

            add = { title: `${RANDOM_NAME}(${gotcha})`, apps: { ...defSys } };

        }else{
            add = { title: `${RANDOM_NAME}(${1})`, apps: { ...defSys } };
        }

        //update next numbered list
        update.push(add);
        setLists(update);

        //set added list as clicked list
        let clicked = getFalsyArray();
        clicked[update.length - 1] = true
        setIsListClicked([...clicked]);

    }

    /**set background of clicked list && send clicked data to parent to send it to content component */
    const backgroundColorHandler = (e, index) => {
        e.preventDefault();
        e.stopPropagation();

        //in case if you don't want to focus on any of list
        let clicked = getFalsyArray();
        if(index >= 0){
            clicked[index] = true
        }
        setIsListClicked([...clicked]);

        //if other line was on focused, set it back to text to show, input to hide
        let activated = getFalsyArray();
        // setIsListHovered([...activated]);
        setActivatedEdit(activated);

       
    }


    
    const subMenuVisibilityHandler = (e, index) => {
        e.preventDefault();
        e.stopPropagation();

        let hovered = getFalsyArray();
        hovered[index] = true
        setIsListHovered([...hovered]);;

    }

    const subMenuHideHandler = e => {
        e.preventDefault();
        // e.stopPropagation();

        let hovered = getFalsyArray();
        setIsListHovered([...hovered]);;
    }


    const editNameHandler = (e, index, title) => {
        e.preventDefault();
        e.stopPropagation();

        //set background color: I do not like to change background color of focused list when editing name.
        backgroundColorHandler(e, -1);
        //set submenu visibility
        subMenuVisibilityHandler(e, index);

        let activated = getFalsyArray();
        activated[index] = true
        // setIsListHovered([...activated]);
        setActivatedEdit(activated);

        //set the input value as the list title
        setEditName(title);

        //THIS IS NOT WORKING! WHY?
        // input.current[index].focus();
    }


    const removeListHandler = (e, index) => {
        e.preventDefault();
        e.stopPropagation();

        let removed = lists.filter((each, i) => i !== index);
        setLists(removed);

        setIsListClicked(prev => prev.filter((each, i) => i !== index));
        setIsListHovered(prev => prev.filter((each, i) => i !== index));
        setActivatedEdit(prev => prev.filter((each, i) => i !== index));

    }

    const preventOtherEvents = (e, index) => {
        e.preventDefault();
        e.stopPropagation();

    }

    const inputNameHandler = (e, index, title) => {
        const value = e.target.value;
        setEditName(value);
    }
    
    const confirmNameHandler = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        
        //set changed name into the list
        const update = lists;
        // [{ title: "All Apps", apps: { ...defSys } }, { title: `${RANDOM_NAME}(2)`, apps: {} }]
        update[index].title = editName;
        setLists(update);

        let activated = getFalsyArray();
        setActivatedEdit(activated);
    }

    return (
        <FlexibleWidth ref={container} width={show ? width : 0} bg={gray_ececec} transition={false} flowy={true}>
            {lists.length > 0 && lists.map((list, index) => {
                
                const title = list.title;
                // const apps = list.apps;
                return (
                    <Row key={title} padding={".5rem"} onClick={e => backgroundColorHandler(e, index)} onMouseOver={e => subMenuVisibilityHandler(e, index)} onMouseOut={subMenuHideHandler}
                        between={true} bg={isListClicked[index] ? gray_ececec : "white"}>
                        <Ul>
                            <Li full={true} start={"true"}>
                                <UnLinkableText hoverbg={"inherit"} display={activatedEdit[index] ? "none" : "inline-block"}>
                                    {title}
                                </UnLinkableText>
                                <Input ref={el => input.current[index] = el} onClick={e => preventOtherEvents(e, index)} onChange={e => inputNameHandler(e, index)} bg={"inherit"}
                                    borderBottom={"gray"} borderColor={"inherit"} width={"100%"} display={activatedEdit[index] ? "inline-block" : "none"} value={editName}>
                                </Input>
                            </Li>
                            <Li hide={isListClicked[index] || isListHovered[index] || activatedEdit[index] ? false : true} marginRight={0} >
                                <ColoredButton onClick={e => activatedEdit[index] ? confirmNameHandler(e, index) : editNameHandler(e, index, title)}
                                    title={activatedEdit[index] ? "Confirm" : "Rename"} color={"white"} bg={"inherit"} borderRadius={"2px"}>
                                    <IconSemi img={activatedEdit[index] ? icon_check_b : icon_edit_b}></IconSemi>
                                </ColoredButton>
                                <ColoredButton onClick={e => removeListHandler(e, index)}
                                    title="Remove" color={red_pastel_light} bg={"inherit"} borderRadius={"2px"}>
                                    <IconSemi img={icon_remove_b}></IconSemi>
                                </ColoredButton>
                            </Li>
                        </Ul>

                        {/* <MlAuto >
                        </MlAuto> */}
                    </Row>
                )
            })}

            <Row padding={0} darkShadow={true} >
                <LinkableText onClick={addListHandler} title={"New Folder"}
                    width={"100%"} padding={".84rem 1rem"} bg={"white"} center={true} hoverbg={gray_ececec} >
                    <IconSemi img={Icon_plus_b}> </IconSemi>
                </LinkableText>
            </Row>
            <Right isSizeMax={false} onMouseDown={widthHandler}></Right>
        </FlexibleWidth>
    )
}
// Icon_plus_b

const Right = styled.div`
    width:5px;
    height:100%;
    background:transparent;
    position:absolute;
    cursor: ${props => props.isSizeMax ? "default" : "col-resize"}; 
    top: 0;
    right: 0;
`;