import React from 'react';
import { Fragment, useState } from "react";
import { Footer, Row } from "../../../../style/Component"
import Navigator from "../../navigator";
import Directory from "./directory";
import Actions from "./actions";
import Result from "./result";

export default function FileManager(props) {
    const focusedMenu = props.focusedMenu;
    const [directory, setDirectory] = useState();
    const [subDirectoryLength, setSubDirectoryLength] = useState(0);

    //To get current(clicked&&focused) directory info, send call back function to child component
    //then, set the 'directory' value to send another child component which is 'Result' component
    const directoryInfo = (data) => {
        setDirectory(data);
        setSubDirectoryLength(Object.keys(data.subDirectory).length);
    };

    return (
        <Fragment>
            <Navigator></Navigator>        
            <Actions></Actions>
            <Row height={`calc( 100% - (35.6px + 41.6px + 26px)`} padding={0} darkShadow={true}>
                {/* will get directory info by using callback function from child component.
                When child component use this callback function, also here the function gets fired with the parameter.
                Then, pass set the directory using 'useState' and pass it to another child component. */}
                <Directory getDirectory={directoryInfo} menu={focusedMenu} ></Directory>
                <Result currentDirectory={directory} name={focusedMenu}></Result>
            </Row>
            <Footer>
                {subDirectoryLength}개 항목
            </Footer>
        </Fragment>
    )
}