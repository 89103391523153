import React from 'react';
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Container } from "../../style/Component"
import FileManager from "./content/filemanager/filemanager";
import Store from "./content/store/store";
import JobManager from "./content/jobmanager/jobmanager";
import AppCollection from "./content/appCollection/appCollection";
import { workManagerBg } from "../../style/Variable";

export default function Content(props) {
    const dNonRef = useRef(null);
    const [content, setContent] = useState('');
    // const names = useSelector(state => state.bgIcon);
    // console.log(names)
    const name = props.name;
        
    useEffect(() => {
        //console.log(name)
        switch (name) {
            case "File Manager": setContent(<FileManager focusedMenu={"File Manager"}/>);
                break;
            case "Store": setContent(<Store />);
                break;
            case "Job Manager": setContent(<JobManager />);
                break;
            case "All Apps": setContent(<FileManager focusedMenu={name} />);
            //case "App Collection": setContent(<AppCollection />);
                break;
            default: 
                break;
        }
    }, [name]);

    const dragPreventHandler = e => {
        e.stopPropagation();
        e.dataTransfer.effectAllowed = "none";
        e.dataTransfer.setDragImage(dNonRef.current, 0, 0);
    };


    //prototype
    return (
        <Container 
            bg={name === "Job Manager" ? workManagerBg : "white"}>
            {content}
            <DNone ref={dNonRef}></DNone>
        </Container>
    )
};

const DNone = styled.div`
    display: none;
`;