import React from 'react';
import { Card, CardContainer, Col5, Col7, ColoredButton, Icon, TextSm } from "../../../../style/Component";
import { flex_a_center } from '../../../../style/Variable';

export default function Purchased() {
    const cardInfos = [
        { title: "iExDesign - IM v1.0.0", icon: "img/G-LOGO-v3.svg", date: "2021.03.19", expireHour: "17hour / 240hour"  },
        { title: "iExDesign - LSPM v1.0.0", icon: "img/G-LSPM-LOGO.svg", date: "2021.03.19", expireHour: "2hour / 240hour"  },
        { title: "iExDesign - LFX v1.0.0", icon: "img/imca_fem_logo_color.svg", date: "2021.03.19", expireHour: "17hour / 240hour" },
        { title: "iExDesign - EMF v1.0.0", icon: "img/imca_fem_logo_color.svg", date: "2021.03.19", expireHour: "17hour / 240hour" },
    ];

    const mlAuto = {
        marginLeft: "auto"
    };
    const flexAlignCenter = {
        display: "flex",
        alignItems: "center"
    }
     
    return (
        <CardContainer gap={13} padding={13} >
            {cardInfos.map(card =>
                <Card key={card.title} shadow={true}>
                    <Col7>
                        <Icon img={card.icon}></Icon>
                        <TextSm margin={".3rem .5rem"}>{card.title}</TextSm>
                    </Col7>
                    <Col5 >
                        <TextSm margin={".2rem .5rem"}>{card.date}</TextSm>
                        <div style={{ ...mlAuto, ...flexAlignCenter }} >
                            <TextSm margin={".2rem .5rem"}>{card.expireHour}</TextSm>
                            <ColoredButton >Extend</ColoredButton>
                        </div>
                    </Col5>
                </Card>
            )}
        </CardContainer>
    )
}