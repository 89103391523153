import React from 'react';
import { Fragment, useEffect, useState } from "react";
import { Img, CardContainer, Container } from "../../../../style/Component";
import { FSAllAppsCardHeight, FSAllAppsCardMinWidth, FSAllAppsCardWidth, FSCarouselDefaultImg, } from "../../../../style/Variable";
import Navigator from "../../navigator";
//import Carousel from 'styled-components-carousel';
import { useSelector } from "react-redux";
import Cards from "./cards";

export default function AllApps() {
    const defaultImages = {
        "imca": { src: FSCarouselDefaultImg },
        "kdh": { src: FSCarouselDefaultImg },
        "finestra": { src: FSCarouselDefaultImg },
    };
    
    const images = useState(defaultImages)[0];
    const modal = useSelector(state => state.modal).Store;
    const width = modal.width;
    // const responsiveWidth = width / 1000 < 1 ? 1 : width / 1000;
    // console.log(width/3.3, FSAllAppsCardMinWidth)
    const cardInfos = [
        { title: "iExDesign - IM v1.0.0", icon: "img/G-LOGO-v3.svg", info: "Equivalent Circuit Simulation Tool for Induction Motor.", name: "Geneers", type: "Web App", users: "213", point: 5 },
        { title: "iExDesign - LSPM v1.0.0", icon: "img/G-LSPM-LOGO.svg", info: "Line-Start Permanent Magnet Simulation Tool for LSPM.", name: "Geneers", type: "Web App", users: "192", point: 5 },
        { title: "iExDesign - LFX v1.0.0", icon: "img/imca_fem_logo_color.svg", info: "Low Frequency Simulation Tool.", name: "Geneers", type: "Web App", users: "82", point: 5 },
        { title: "iExDesign - EMF v1.0.0", icon: "img/imca_fem_logo_color.svg", info: "FEM Analysis Tools for Permanent Magnet Motor.", name: "Geneers", type: "Web App", users: "82", point: 5 },
    ];

    useEffect(() => {
        
    })
    return (
        <Fragment>
            <Navigator></Navigator>
            <Container height={"calc(100% - 36.5px)"} flowy={true}>

              

                {/* <CardContainer row={FSAllAppsCardHeight} column={FSAllAppsCardWidth}> */}
                <CardContainer
                    row={FSAllAppsCardHeight} gap={13} padding={13}
                    repeat={width / 3.3 < 180 ? 1 : width / 3.3 < FSAllAppsCardMinWidth ? 2 : width / 3.3 < FSAllAppsCardWidth ? 3 : 4}>
                    {cardInfos.map(card =>
                        <Cards key={card.title} img={card.icon} title={card.title} info={card.info} name={card.name}
                            type={card.type} download={card.users} point={card.point} ></Cards>
                    )}
                </CardContainer>
            </Container>
        </Fragment>
    )
}



  //<Carousel center infinite showArrows={false} showIndicator slidesToShow={1}>
                //    {Object.entries(images).map(img => {
                //        const name = img[0];
                //        const attr = img[1];

                //        return (
                //            <div key={name}>
                //                {/* <Img alt={name} width={FSCarouselImageWidth} height={FSCarouselImageHeight} src={attr.src} ></Img> */}
                //                <Img alt={name} width={`${width}px`} src={attr.src} ></Img>
                //            </div>
                //        )
                //    })}
                //</Carousel>