import React from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { StatusbarContainer, StatusbarNav } from '../../style/Component';
import Activebar from './activebar/activebar';
import Hamburgerbar from './hamburger/hamburger';
import Staticbar from './static/staticbar';

/**
 * Compute which is the highest z-index of modal, and update the value by plus 1 to status bar z-index
 * so that the modal does not show up  the status bar.
 * @returns 
 */
export default function Statusbar(props) {
    const current = useSelector(state => state.current);
    const modal = useSelector(state => state.modal);
    const zIndex = useRef(1);
                                                          
    // console.log(current.name, modal)
    if((current.name.length > 0) && (Object.keys(modal).length > 0)){
        zIndex.current = Object.entries(modal).filter(each => each[0] === current.name)[0][1].zIndex;
    }

    // let zIndex = current.length > 0 ? Object.entries(modal).filter( m => m[0] === current )[0][1].zIndex : 1;
    // Object.entries(modal).forEach( m => console.log(m[0]))
    //need to check if zIndex become a problem
    // const modal = useSelector(state => state.modal);
    // const zIndexes = useRef([]);

    // if(Object.keys(modal).length > 0){
    //     //compare which index of modal is having highest number
    //     zIndexes.current = Object.entries(modal).map(each => { return { name:each[0] , zIndex: each[1].zIndex } });
    // }
    

    return(
        <StatusbarContainer zIndex={zIndex.current + 1}>
            <StatusbarNav >
                <Hamburgerbar zIndex={zIndex.current + 1}></Hamburgerbar>
                <Activebar></Activebar>
                <Staticbar zIndex={zIndex.current + 1} history={props.history}></Staticbar>
            </StatusbarNav>
        </StatusbarContainer>
    );
}