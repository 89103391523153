import React from 'react';
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { TextSm, DirectoryBar, ToggleList, LinkableText, ToggleUl, IconXs, FlexibleWidth } from "../../../../style/Component";
import { FMContentListHeight } from "../../../../style/Variable";

export default function Directory(props) {
    const menu = props.menu; //the triggered menu that makes this modal show up
    const setDirectory = props.getDirectory;
    const container = useRef();
    const dispatch = useDispatch();
    const bgIcon = useSelector(state => state.bgIcon);
    const width = useSelector(state => state.widthResizer)[menu].width;
    const allApps = useSelector(state => state.allApps);
    const directory = [{ name: "Finestra", displayName: "Background", icon: '/img/finestra-c.svg', subDirectory: bgIcon },
                        { name: "All Apps", displayName: "All Apps", icon: "/img/applibrary-c.svg", subDirectory: allApps }];
    //handle directory bar color when it's focused
    const [isDirectoryBarFocused, setIsDirectoryBarFocused] = useState(new Array(directory.length).fill(false));
    /*const [isDirectoryClicked, setIsDirectoryClicked] = useState(new Array(directory.length).fill(false).fill(true, 0, 1));*/
    const [isDirectoryClicked, setIsDirectoryClicked] = useState(new Array(directory.length).fill(false).map((each, index) => directory[index].name === menu ? true : false ));
    //use setState when there is new bg icon added to get update
    // const [icons, setIcons] = useState(bgIcon);
    //handle directory's DirectoryBar color &&  submenu display when the directory is focused
    // const [stillFocus, setStillFocus] = useState([]);
    //handle sub directory height
    const [subDirectoryHeight, setSubDirectoryHeight] = useState(new Array(directory.length).fill(0));
    //the line height of directory
    //setting this property because of the transition effect. Without specific height, the css effect is not going to work.
    const listHeight = FMContentListHeight;

    useEffect(() => {
        let focusedListIndex = isDirectoryClicked.findIndex(each => each === true);
        //if no matching data, set default index as 0
        if (focusedListIndex === -1) {
            focusedListIndex = 0;
            const firstDirectoryClicked = isDirectoryClicked.slice();
            firstDirectoryClicked[0] = true;
            setIsDirectoryClicked(firstDirectoryClicked);
            

        };
        setDirectory(directory[focusedListIndex])
        
    }, [isDirectoryClicked, bgIcon, subDirectoryHeight]);


    /**This event get fired when user clicks only DIRECTORY BAR. */
    const submenuHandler = (e, index, subDir) => {
        e.preventDefault();
        e.stopPropagation();

        //compute if sub directory is a folder or no
        const subDirFolders = [];
        subDir.forEach((dir, index) => {
            if (dir.type === "folder") {
                subDirFolders.push(index);
            }
        })
        let focused = isDirectoryBarFocused;

        //set length of submenu to make height as tall as the length of submenu
        let subLength = subDirectoryHeight;
        if (isDirectoryBarFocused[index] === undefined || isDirectoryBarFocused[index] === false) {
            //if sub directory is folder => add to subdirectory heigth so that it can be collapsible
            subLength[index] = parseInt(subDirFolders.length);
            setSubDirectoryHeight(subLength);
        } else {
            subLength[index] = 0;
            setSubDirectoryHeight(subLength);
            // setSubDirectoryHeight(0);
        }
        //set directory bar color as its opposite value
        focused[index] = !isDirectoryBarFocused[index];
        setIsDirectoryBarFocused([...focused]);

        // //directory focus on
        // setFocus([...newValue]);
        // //and its DirectoryBar focus on
        // setStillFocus([...newValue]);
    };


    /**When the sub directory is focused */
    const subdirectorytHandler = (e, index) => {
        e.preventDefault();
        e.stopPropagation();


         ////submenu get focused => set focus
         //let newValue = new Array(subFocus.length);
         //if(subFocus[index] === undefined){
         //    newValue[index] = true;
         //} 
         ////sub directory focus on
         //setSubFocus([...newValue]);
         ////main directory focus out
         //setFocus([]);
    }

    //send current clicked(focused) directory to parent component
    const resultHandler = (e, index) => {
        e.preventDefault();
        e.stopPropagation();

        // setDirectory(direc);

        //set directory background color
        //because we are creating new array, set its indexed value always true, others will be always false
        let clicked = new Array(directory.length).fill(false);
        clicked[index] = true
        setIsDirectoryClicked([...clicked]);

    };

    //which modal you like to resize width of side bar?
    const widthHandler = e => {
        e.preventDefault();
        e.stopPropagation();

        const name = menu;
        const containerInfo = container.current.getBoundingClientRect();
        const x = containerInfo.x;
        dispatch({ type: "resizeWidth/start", name, x });
        dispatch({ type: "current/active", name });
        // const rectWidth = containerInfo.width;
    }


    return (
        <FlexibleWidth width={width} ref={container} flowy={true}>
            <ToggleUl show={true} height={(listHeight * directory.length) + (listHeight * subDirectoryHeight.reduce((a, b) => a + b, 0))}>

                {directory.map((direc, index) => (
                  //list of directory
                    <ToggleList key={direc.name} show={true} >

                        {/*//toggler*/}
                        <LinkableText isFocused={isDirectoryClicked[index]} onClick={e => resultHandler(e, index)} depth={0}>
                            {/* <DirectoryBar isFocused={isDirectoryBarFocused[index]} onClick={e => submenuHandler(e, index, Object.keys(direc.subDirectory).length)} ><div></div></DirectoryBar> */}
                            <DirectoryBar isFocused={isDirectoryBarFocused[index]} onClick={e => submenuHandler(e, index, direc.subDirectory)} ><div></div></DirectoryBar>
                            <IconXs img={direc.icon}></IconXs>
                            <TextSm padding={"0 .3rem"}>
                                &nbsp;{direc.displayName}
                            </TextSm>
                        </LinkableText>

                        {/*sub directory*/}
                        <ToggleUl show={subDirectoryHeight[index] > 0} height={listHeight * subDirectoryHeight[index]}>
                            {(direc.subDirectory).map((subDirec, subIndex) => {
                                return subDirec.type === "folder" ? (
                                    <ToggleList key={subDirec.name} show={true} >
                                        <LinkableText isFocused={false} onClick={e => subdirectorytHandler(e, subIndex)} depth={1}>
                                            {/*<DirectoryBar isFocused={false}><div></div></DirectoryBar>*/}
                                            <IconXs img={subDirec.icon}></IconXs>
                                        &nbsp;{subDirec.name}
                                        </LinkableText>
                                    </ToggleList>
                                ) : null;
                            })}
                        </ToggleUl>

                    </ToggleList>
                ))}

            </ToggleUl>

            <Right isSizeMax={false} onMouseDown={widthHandler}></Right>
        </FlexibleWidth>
    )
}

const Right = styled.div`
    width:5px;
    height:100%;
    background:transparent;
    position:absolute;
    cursor: ${props => props.isSizeMax ? "default" : "col-resize"}; 
    top: 0;
    right: 0;
`;