/**default btns on hamburger menu left side */

const defBtnItems = {
    //'App Collection': 
    //{ name: 'App Collection', icon: '/img/applibrary-w.svg', width: "657", height: "518", target: "modal"},
    'File Manager':
        { name: 'File Manager', icon: '/img/filemanager-c.svg', width: "960", height: "596", target: "modal", date: new Date().toLocaleString()  },
    'All Apps':
        { name: 'All Apps', icon: '/img/applibrary-c.svg', width: "960", height: "596", target: "modal", date: new Date().toLocaleString()  },
    'Store':
        { name: 'Store', icon: '/img/store-c.svg', width: "785", height: "836", target: "modal", date: new Date().toLocaleString()  },
    //'Setting':
    //    { name: 'Setting', icon: '/img/setting-c.png', width: "560", height: "436", target: "modal", date: new Date().toLocaleString()  },
};


/**
 * default btns on dropdown Hamburger menu left side
 * 
 */

export default function defBtn(state = defBtnItems, action) {
    switch (action.type) {
        //case "hi":
        //    return;
        default:
            return state;
    }
}
