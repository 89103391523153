import React, { useEffect, useRef } from 'react';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { userManager } from '../../../oidcClient';
import { ColoredButton, TextStrong, Ul, DropdownModal, Col7, Row, TextSm, Container, Col2, Col3, ProfileImage, ColLi, IconFullCover, IconSemi } from "../../../style/Component";
import { navTop_height, gray_979797, gray_cecece, gray_ececec, Icon_logout, Icon_lock } from "../../../style/Variable";
import Alert from "./alert";
//import { useHistory, Redirect } from 'react-router-dom';

export default function Notice(props) {
    const height = props.height;
    const width = props.width;
    const zIndex = props.zIndex;
    //const defAlerts = [
    //    { icon: '/img/account_man.png', title: 'Account', time: '2020.04.29 14:23', info: "Benvenuti! Ciao, Jihye Lee! Cosa mangiare la sera a cena?" },
    //    { icon: '/img/store-c.svg', title: 'Store', time: '2020.04.29 15:23', info: "Travel gives you more experience of life. Would you like to expand your experience by tryinig our new application!" },
    //];

    const [alerts, setAlerts] = useState([]);
    const modal = useSelector(state => state.modal);
    const dispatch = useDispatch();
    const user = useSelector(state => state.userInfo);
    //const user = { icon: '/img/account_man.png', name: 'JIHYE LEE', email: 'jhlee@clew.tech' };
    // const dispatch = useDispatch();
    const userInfoHeight = "90px";
    const settingIcon = { name: "Setting", icon: '/img/set8-b.svg', width: "560", height: "436" };
    //const isMounted = useRef(false);
    //const navigate = useNavigate();
    //const history = useHistory();

    //const [isRedirect, setIsRedirect] = useState(false);

    useEffect(() => {
        let isMounted = true;

        //update alert list asynchronously

        userManager.getUser().then(function (userInfo) {
            const url = "/api/rightsidebarnotice/notices";

            fetch(url, {
                method: "GET",
                headers: {
                    //'Authorization': "Bearer " + userInfo.access_token
                }
            }).then(res => res.json())
              .then((result) => {
                const alertList = result;
                if (isMounted) {
                    //console.log(`%calert list`, "color: dodgerblue;, font-size: 1rem;");
                    setAlerts(alertList);
                }
               }).catch(err => console.error(`%c${err},error from listing notice`, "color: red; font-size: 1.5rem;"));
            });

        //as soon as if the component is unmounted => cleanup callback

        return () => { isMounted = false };

    },[]);
 

    const dropdownHandler = e => {
        e.preventDefault();
        e.stopPropagation();
    }

    const clearAllHandler = e => {
        e.preventDefault();
        e.stopPropagation();
        setAlerts([]);
    }

    const updateAlertList = index => {
        const removed = alerts.filter((alert, i) => i !== index);
        setAlerts(removed);
    }


    /**
 * Activate modal
 * Modal item already have added from defSys ? RUN : ADD
 */
    const actModalHandler = (e, name, info) => {
        e.preventDefault();
        e.stopPropagation();
        
        //if exist => add
        if (modal[name]) {
            // if (typeof modal[name] !== undefined && !modal[name].active) {
            if (typeof modal[name] !== undefined) {
                dispatch({ type: "modal/activate", name });
                //commented because activate and zIndex actually works in same say
                //if modal is already working, set zIndex higher of the modal
                // }else if (typeof modal[name] !== undefined && modal[name].active) {
                // dispatch({ type: "modal/zIndex", name })
            }
            //if doesn't exist => update
        } else {
            const y = Math.random() * (150 - 100) + 100;
            const x = Math.random() * (350 - 300) + 300;
            dispatch({ type: "modal/update", name, icon: info.icon, x, y, width: info.width, height: info.height });
        }

        dispatch({ type: "statusbar/update", name, icon: info.icon });
        dispatch({ type: "current/active", name })
        dispatch({ type: "staticBtn/all/deactivate" });
        dispatch({ type: "ctxtmenu/all/deactivate" });
    };

    const lockPageHandler = e => {
        e.preventDefault();
        e.stopPropagation();
        //navigate('/lock');
        //history.push('lock');
        //setIsRedirect(true);

        dispatch({ type: "isLock/active" });
        window.localStorage.setItem('lock', 'true');
        
    }

    const logoutHandler = e => {
        e.preventDefault();
        e.stopPropagation();

        userManager.signoutRedirect();
    }


    return (
        <DropdownModal
            onClick={dropdownHandler}
            height={height} width={width} zIndex={zIndex} right={0}>
            <Row height={userInfoHeight} bg={gray_cecece}>
                <Col3 padding={".8rem"}>
                    <ProfileImage>
                        <IconFullCover img={'/img/account_man.png'}></IconFullCover>
                    </ProfileImage>
                </Col3>
                <Col7 column={true} center={true} >
                    <h6>{user.name}</h6>
                    <TextSm>{user.email}</TextSm>
                </Col7>
                <Col2 center={true} align={"center"}>
                    <ColoredButton bg={gray_ececec} borderRadius={"2px"} title={"Setting"}
                        onClick={e => actModalHandler(e, settingIcon.name, { icon: settingIcon.icon, width: settingIcon.width, height: settingIcon.height })}>
                        <IconSemi img={settingIcon.icon}></IconSemi>
                    </ColoredButton>
                    <ColoredButton bg={gray_ececec} borderRadius={"2px"} title={"Lock"}
                        onClick={lockPageHandler}>
                        <IconSemi img={Icon_lock}></IconSemi>
                    </ColoredButton>
                    <ColoredButton bg={gray_ececec} borderRadius={"2px"} title={"Logout"}
                        onClick={logoutHandler}>
                        <IconSemi img={Icon_logout}></IconSemi>
                    </ColoredButton>
                </Col2>
            </Row>
            <Row>
                <Ul >
                    <ColLi borderB={true} width={100}>
                        <Row between={true} align={"center"}>
                            <TextStrong>Notice</TextStrong>
                            <ColoredButton onClick={clearAllHandler} bg={"white"} color={gray_979797} >
                                <TextSm>Clear All</TextSm>
                            </ColoredButton>
                        </Row>
                    </ColLi>
                </Ul>
                <Container height={`calc(${height}px - ${navTop_height}px - ${userInfoHeight} - 40px`} flowy={true}>
                    {alerts.map((alert, index) => 
                        !alert.isUserRead && 
                            <Alert key={alert.appId} alert={alert} index={index} updateAlertList={updateAlertList}></Alert>
                        
                    )}

                </Container>
            </Row>
        </DropdownModal>
    )
}