import React from 'react';
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Modal } from "../../style/Component";
import { navTop_height } from '../../style/Variable';
import Content from "./content";
import ModalSttbar from "./statusbar";


/**
 * You get name from parent component, and search and match from modal redux store.
 * @param {*} props 
 * @returns 
 */
export default function ModalContainer(props) {
    const dispatch = useDispatch();
    const modal = useSelector(state => state.modal);
    const draggable = useSelector(state => state.draggable);
    const name = props.name;
    const attr = modal[props.name];
    const modalRef = useRef(null);
    const modalSubRef = useRef(null);
    const dNonRef = useRef(null);
    // const NONE = "none";
    // const MOVE = "move";
    const TOP = "top";
    const TOP_LEFT = "topLeft";
    const TOP_RIGHT = "topRight";
    const LEFT = "left";
    const BOTTOM_LEFT = "bottomLeft";
    const BOTTOM = "bottom";
    const BOTTOM_RIGHT = "bottomRight";
    const RIGHT = "right";
    const maxSize = "100%";
    const isWidthMax = attr.width === maxSize;
    const isHeightMax = attr.height === maxSize;

   
    const dragStartHandler = e => {
        e.preventDefault();
        e.stopPropagation();
    // const dragStartHandler = e => {
    //     e.preventDefault();
    //     e.stopPropagation();
        //e.dataTransfer.setData('text/plain', name);
        // is for non-image div when mouse drags content div
        //if (e.target !== modalRef.current) {
        //    e.dataTransfer.effectAllowed = "none";
        //    e.dataTransfer.setDragImage(dNonRef.current, 0, 0);
        //    return;
        //}

        //setTimeout(() => modalRef.current.style.top = "-10000px", 0);

        let mousePositionOnModal = {
            x: e.nativeEvent.layerX,
            y: e.nativeEvent.layerY
        };

        //setTimeout(() => modalRef.current.style.display = "none", 0);

        //e.dataTransfer.effectAllowed = "copy";

        //const clonedModal = document.createElement('div');
        //clonedModal.id = "drag-ghost";
        //clonedModal.style.position = "absolute";
        //clonedModal.style.top = "-1000px";
        //clonedModal.style.width = `${attr.width}px`;
        ////clonedModal.style.height = `${attr.height}px`;
        //clonedModal.style.background = "red";

        //const statusbar = document.createElement('div');
        //statusbar.style.width = "80px";
        //statusbar.style.height = "150px";
        //statusbar.style.display = "flex";
        //statusbar.style.justifyContent = "space-between";
        //const span = document.createElement('span');
        //span.innerText = props.name;
        //const span2 = document.createElement('span');
        //span2.innerText = "close";

        //statusbar.appendChild(span);
        //statusbar.appendChild(span2);
        //clonedModal.appendChild(statusbar);


        //modalRef.current.appendChild(clonedModal);

        //e.dataTransfer.setDragImage(clonedModal, 0, 0);

        dispatch({ type: "current/active", name });
        dispatch({ type: "modal/activate", name });
        dispatch({ type: "draggable/start", name, target: modalRef.current, mousePosition: mousePositionOnModal });

    }

    const dragHandler = e => {
        //console.log('triggered')
        //setTimeout(() => modalRef.current.style.top = "-10000px", 0);
        //e.preventDefault();
        //e.stopPropagation();

    }

    const zIndexHandler = e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch({ type: "modal/activate", name });
        dispatch({ type: "current/active", name });
        dispatch({ type: "hamburger/deactivate" });
        dispatch({ type: "staticBtn/all/deactivate" });
        dispatch({ type: "ctxtmenu/all/deactivate" });

    }

    const ctxtZIndexHandler = e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch({ type: "modal/activate", name });
        dispatch({ type: "current/active", name });
        dispatch({ type: "hamburger/deactivate" });
        dispatch({ type: "staticBtn/all/deactivate" });
        // dispatch({ type: "ctxtmenu/all/deactivate" });

    }

    /**
     *when user clicks to start resize => set zindex++ && send infos to redux
     * @param {any} clickedDiv - which area of modal has been clicked
     * @param {any} e - event param
     */
    const clickToResizeModalHandler = (clickedDiv, e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isHeightMax && isWidthMax) {
            return;
        }

        //get current mouse position
        let mousePosition = {
            x: e.pageX,
            y: e.pageY
        }

        dispatch({ type: "current/active", name })
        dispatch({ type: "modal/activate", name });
        dispatch({ type: "resize/start", name, mousePosition, clickedDiv });
    }

    //const clonedModal = () => {
    //    return (
    //        <Modal ref={modalSubRef} y={attr.y} x={attr.x} width={attr.width} height={attr.height} zIndex={attr.zIndex}>
    //            <ModalSttbar name={name}></ModalSttbar>
    //            <Content name={name}></Content>
    //        </Modal>
    //        )
    //}

    return (
        // <Modal ref={modalRef} onClick={zIndexHandler} onMouseDown={dragStartHandler}
        <Modal ref={modalRef}
        /*draggable="true" onDragStart={dragStartHandler} onDrag={dragHandler}*/
            //this dragStartHandler function has been moved to statusbar event function
            /*onMouseDown={dragStartHandler}*/
            onClick={zIndexHandler} onContextMenu={ctxtZIndexHandler}
            y={attr.y} x={attr.x} width={attr.width} height={attr.height} zIndex={attr.zIndex}>
            <ModalSttbar name={name} modal={modalRef}></ModalSttbar>
            <Content name={name}></Content>
            <DNone ref={dNonRef}></DNone>
            {/*<Modal ref={modalSubRef} style={{ display: "none" }} y={attr.y} x={attr.x} width={attr.width} height={attr.height} zIndex={attr.zIndex}>*/}
            {/*    <ModalSttbar name={name}></ModalSttbar>*/}
            {/*    <Content name={name}></Content>*/}
            {/*</Modal>*/}
            <Left isSizeMax={isHeightMax && isWidthMax ? true : false} onMouseDown={e => clickToResizeModalHandler(LEFT, e)}></Left>
            <Top isSizeMax={isHeightMax && isWidthMax ? true : false} onMouseDown={e => clickToResizeModalHandler(TOP, e)}></Top>
            <TopLeft isSizeMax={isHeightMax && isWidthMax ? true : false} onMouseDown={e => clickToResizeModalHandler(TOP_LEFT, e)}></TopLeft>
            <Bottom isSizeMax={isHeightMax && isWidthMax ? true : false} onMouseDown={e => clickToResizeModalHandler(BOTTOM, e)}></Bottom>
            <BottomRight isSizeMax={isHeightMax && isWidthMax ? true : false} onMouseDown={e => clickToResizeModalHandler(BOTTOM_RIGHT, e)}></BottomRight>
            <BottomLeft isSizeMax={isHeightMax && isWidthMax ? true : false} onMouseDown={e => clickToResizeModalHandler(BOTTOM_LEFT, e)}></BottomLeft>
            <TopRight isSizeMax={isHeightMax && isWidthMax ? true : false} onMouseDown={e => clickToResizeModalHandler(TOP_RIGHT, e)}></TopRight>
            <Right onMouseDown={e => clickToResizeModalHandler(RIGHT, e)}></Right>
        </Modal>
    )
}


const DNone = styled.div`
    display: none;
`;

const Bottom = styled.div`
    width:100%;
    height:5px;
    background:transparent;
    position:absolute;
    cursor: ${props => props.isSizeMax ? "default" : "ns-resize"};
    bottom: 0;
    left: 0;
`;

const BottomRight = styled.div`
    width:5px;
    height:5px;
    background:transparent;
    position:absolute;
    cursor: ${props => props.isSizeMax ? "default" : "nwse-resize"};
    bottom: 0;
    right: 0;
    z-index:1;
`;

const Right = styled.div`
    width:5px;
    height:100%;
    background:transparent;
    position:absolute;
    top: 0;
    right: 0;
    cursor: ${props => props.isSizeMax ? "default" : "ew-resize"}; 
    `;

const Top = styled.div`
    width:100%;
    height:5px;
    background:transparent;
    position:absolute;
    cursor: ${props => props.isSizeMax ? "default" : "ns-resize"}; 
    top: 0;
    left: 0;
`;

const TopLeft = styled.div`
    width:5px;
    height:5px;
    background:transparent;
    position:absolute;
    cursor: ${props => props.isSizeMax ? "default" : "nwse-resize"}; 
    top: 0;
    left: 0;
    z-index:1;
`;

const Left = styled.div`
    width:5px;
    height:100%;
    background:transparent;
    position:absolute;
    cursor: ${props => props.isSizeMax ? "default" : "ew-resize"}; 
    bottom: 0;
    left: 0;
`;

const BottomLeft = styled.div`
    width:5px;
    height:5px;
    background:transparent;
    position:absolute;
    cursor: ${props => props.isSizeMax ? "default" : "nesw-resize"}; 
    bottom: 0;
    left: 0;
    z-index:1;
`;

const TopRight = styled.div`
    width:5px;
    height:5px;
    background:transparent;
    position:absolute;
    cursor: ${props => props.isSizeMax ? "default" : "nesw-resize"}; 
    top: 0;
    right: 0;
    z-index:1;
`;