import React from 'react';
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ColLi, TextSm, FlexFull, Row, FilterBtn, Ul, IconXxs } from "../../../../style/Component";
import { FMContentListHeight, modalHeight, navTop_height } from "../../../../style/Variable";
import Ctxtmenu from './ctxtmenu';
// import { FMColumnWidth } from "../../../../style/Variable";

export default function Result(props) {
    const name = props.name;
    const dispatch = useDispatch();
    const types = useSelector(state => state.columnResizer[name].types);
    const ctxtmenu = useSelector(state => state.ctxtmenu);
    const modal = useSelector(state => state.modal);
    const currentModal = modal[name];
    const width = useSelector(state => state.widthResizer)[name].width;
    const listRef = useRef(new Array(Object.entries(types).length));
    //this files show subdirectory
    const [files, setFiles] = useState();
    const [focusedIcon, setFocusedIcon] = useState(["", ""]); //[type, data]
    const [mousePosition, setMousePosition] = useState([0, 0]);
    const container = useRef();
    const [clicked, setClicked] = useState([]);

    useEffect(() => {
        if (typeof props.currentDirectory !== "undefined") {
            const sub = props.currentDirectory.subDirectory;
            setFiles(sub)

            const defValue = new Array(Object.keys(sub).length).fill(false);
            setClicked(defValue);
        }

    }, [props.currentDirectory]);

    const widthHandler = (e, target, index) => {
        e.preventDefault();
        e.stopPropagation();

        //get container info including size and position        
        const containerInfo = container.current.getBoundingClientRect();
        const rectWidth = containerInfo.width;
        //targeted list info including size and position
        const list = listRef.current[index].getBoundingClientRect();
        const listX = list.x;
        const listWidth = list.width;
        //targeted list's right next sibling
        const siblingInfo = listRef.current[index + 1].getBoundingClientRect();
        //and its name
        const siblings = Object.entries(types).map(type => type[0]);
        const targetIndex = siblings.findIndex(child => child === target);
        const siblingIndex = targetIndex + 1;
        const sibling = siblings[siblingIndex];

        //send information to resize column function to compute the percentage of width
        dispatch({ type: "resizeColumn/start", name, target, sibling, siblingInfo, listX, listWidth, rectWidth });

    };

    const ctxtMenuHandler = (e, attr, index) => {
        e.preventDefault();
        //if you use below function => modal zindex setting function does not work
        //e.stopPropagation();

        dispatch({ type: "ctxtmenu/all/deactivate" });

        setFocusedIcon(attr);

        const x = e.pageX - currentModal.x - width;
        const y = e.pageY - currentModal.y - modalHeight - navTop_height - 35.6 - 41.6;
        //const y = e.pageY - currentModal.y;
        setMousePosition([x, y]);


        const defValue = new Array(Object.keys(files).length).fill(false);
        defValue[index] = true;
        setClicked(defValue);

        dispatch({ type: "ctxtmenu/update", name, });
        dispatch({ type: "current/active", name })

    }

    const bgColorHandler = (e, index) => {
        e.preventDefault();
        //e.stopPropagation();

        const defValue = new Array(Object.keys(files).length).fill(false);
        defValue[index] = true;
        setClicked(defValue);

        dispatch({ type: "current/active", name })

        dispatch({ type: "ctxtmenu/all/deactivate" });
    }

    const actModalHandler = (e, attr) => {
        e.preventDefault();
        e.stopPropagation();
        const target = attr.target;
        const name = attr.name;
        const icon = attr.icon;

        if (target === "modal") {
            const width = attr.width;
            const height = attr.height;

            //if exist => add
            if (modal[name]) {
                // if (typeof modal[name] !== undefined && !modal[name].active) {
                if (typeof modal[name] !== undefined) {
                    dispatch({ type: "modal/activate", name });
                    //commented because activate and zIndex actually works in same say
                    //if modal is already working, set zIndex higher of the modal
                    // }else if (typeof modal[name] !== undefined && modal[name].active) {
                    // dispatch({ type: "modal/zIndex", name })
                }
                //if doesn't exist => update
            } else {
                const y = Math.random() * (150 - 100) + 100;
                const x = Math.random() * (350 - 300) + 300;

                dispatch({ type: "modal/update", name, icon, x, y, width, height });
            }

            dispatch({ type: "statusbar/update", name, icon });
            dispatch({ type: "current/active", name })


        } else {
            const url = attr.url;
            const target = attr.target;
            window.open(url, target);
        }

        dispatch({ type: "ctxtmenu/all/deactivate" });

    }
    // const getTextWidth = (text, font) => {
    //     const canvas = document.createElement('canvas');
    //     const context = canvas.getContext('2d');
    //     context.font = font || getComputedStyle(document.body).font;
    //     return context.measureText(text).width;
    // }

    return (
        <FlexFull ref={container}>
            <Row padding={0}>
                <Ul>
                    {Object.entries(types).map((type, index) => {
                        const name = type[0];
                        // console.log(type[1].width)
                        return (
                            <ColLi key={name} width={type[1].width} height={FMContentListHeight} borderR={true} borderB={true} ref={el => listRef.current[index] = el}>
                                <FilterBtn isFull={true}>
                                    <TextSm padding={"0 .3rem"}>{name}</TextSm>
                                </FilterBtn>
                                <Right isSizeMax={false} onMouseDown={e => widthHandler(e, name, index)}></Right>
                            </ColLi>
                        )
                    })}
                </Ul>
            </Row>
            <Row padding={0}>
                {
                    typeof files !== "undefined" && Object.entries(files).map((file, index) => {
                        const attr = file[1];
                        return (
                            <Ul key={attr.name} isHoverEffect={true} bg={clicked[index]} onDoubleClick={e => actModalHandler(e, attr)}
                                onContextMenu={e => ctxtMenuHandler(e, attr, index)} onClick={e => bgColorHandler(e, index)}>
                                <ColLi width={types.Name.width} height={FMContentListHeight}>
                                    <TextSm padding={"0 .3rem"}>
                                        <IconXxs img={attr.icon}></IconXxs>
                                        {/* {getTextWidth(attr.name) === } */}
                                        {/* &nbsp;{types.Name.width < getTextWidth(attr.name) ? `${attr.name.substring(0, 9)}...` : attr.name} */}
                                   &nbsp;{attr.name}
                                    </TextSm>
                                </ColLi>
                                <ColLi width={types.Size.width} height={FMContentListHeight}>
                                    <TextSm padding={"0 .3rem"}>
                                        {attr.name}
                                    </TextSm>
                                </ColLi>
                                <ColLi width={types.Form.width} height={FMContentListHeight}>
                                    <TextSm padding={"0 .3rem"}>
                                        {attr.form}
                                    </TextSm>
                                </ColLi>
                                <ColLi width={types.Date.width} height={FMContentListHeight}>
                                    <TextSm padding={"0 .3rem"}>
                                        {attr.date}
                                    </TextSm>
                                </ColLi>
                            </Ul>
                        )
                    })
                }
            </Row>
            {
                ctxtmenu[name] ? ctxtmenu[name].show &&
                    <Ctxtmenu focusedIcon={focusedIcon} mousePosition={mousePosition} name={name}></Ctxtmenu> : null

            }
        </FlexFull>
    )
};


const Right = styled.div`
    width:5px;
    height:100%;
    background:transparent;
    position:absolute;
    cursor: ${props => props.isSizeMax ? "default" : "col-resize"}; 
    top: 0;
    right: 0;
`;
