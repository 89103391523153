import React from 'react';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Button, Col, Col2, Col9, IconLg, IconTiny, Row, TextSm, TextStrong, Ul, ColLi, P } from "../../../style/Component";
import { Icon_close_b, gray_979797, gray_f1f1f1, Icon_arrowUp_b, Icon_arrowDown_b } from "../../../style/Variable";

export default function Alert(props) {
    const alert = props.alert;
    const isModal = props.isInternalApp;
    const index = props.index;
    const updateAlertList = props.updateAlertList;
    const [isCollapsed, setIsCollapsed] = useState(true);
    const modal = useSelector(state => state.modal);
    const defSys = useSelector(state => state.defSys);
    const dispatch = useDispatch();


    const mt_sm = {
        marginTop: "6px"
    };

    const removeAlertHandler = e => {
        e.preventDefault();
        e.stopPropagation();
        updateAlertList(index);
    }

    const collapseAlertHandler = e => {
        e.preventDefault();
        e.stopPropagation();
        setIsCollapsed(prev => !prev)
    }

    const actModalHandler = (e, ) => {
        e.preventDefault();
        //e.stopPropagation();
        const name = alert.name;
        const icon = alert.iconUrl;

        if (modal[name]) {
            if (typeof modal[name] !== undefined) {
                dispatch({ type: "modal/activate", name });
                
            }
            //if doesn't exist && update
        } else {
            const y = Math.random() * (150 - 100) + 100;
            const x = Math.random() * (350 - 300) + 300;
            const width = alert.modalSizeWidth;
            const height = alert.modalSizeHeight;
            //const height = defSys.modal[name].height;
            dispatch({ type: "modal/update", name, icon, x, y, width, height });
        }

        dispatch({ type: "statusbar/update", name, icon });
        dispatch({ type: "current/active", name })
        dispatch({ type: "staticBtn/all/deactivate" });

    }

    const actWebHandler = e => {

        window.open(alert.appRedirectUrl, "_blank");
    }

    return (
        <Card bg={gray_f1f1f1} margin={".5rem"} onClick={isModal ? actModalHandler : actWebHandler}>
            <Row>
                <Col2 start="true" align={"center"} column="true">
                    <IconLg img={alert.iconUrl}></IconLg>
                    <span style={mt_sm}>
                        {alert.displayName.length > 7 ? `${alert.displayName.substring(0, 6)}...` : alert.displayName}
                    </span>
                </Col2>
                <Col9 column="true" >
                    <TextStrong>{alert.title}</TextStrong>
                    <P>{isCollapsed && alert.message.length > 80 ? `${alert.message.substring(0, 81)}..` : alert.message}</P>
                    <TextSm color={gray_979797}>{alert.ragistrationDate}</TextSm>
                </Col9>
                {/* <Col start={"true"} column="true"> */}
                <Col >
                    <Ul column="true">
                        <ColLi height={20}>
                            <Button onClick={removeAlertHandler}  >
                                <IconTiny img={Icon_close_b}></IconTiny>
                            </Button>
                        </ColLi>
                        {
                            alert.message.length > 80 && (
                                <ColLi height={20}>
                                    <Button onClick={collapseAlertHandler}  >
                                        <IconTiny img={isCollapsed ? Icon_arrowDown_b : Icon_arrowUp_b }></IconTiny>
                                    </Button>
                                </ColLi>
                            )
                        }
                    </Ul>

                </Col>
            </Row>
        </Card>
    )
}