import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Li, MlAuto, StatusbarButton, Ul } from "../../../style/Component";
import Notice from "./notice";

export default function Staticbar(props) {
    const zIndex = props.zIndex;
    // const alert = { name: "Notice", icon: "/img/notice-w.svg" };
    const staticBtn = useSelector(state => state.staticBtn);
    const notice = staticBtn.Notice;
    const dispatch = useDispatch();
    const [height, setHeight] = useState(window.innerHeight);


    useEffect(() => {
        const getHeight = e => {
            setHeight(window.innerHeight);
        }

        window.addEventListener("resize", getHeight);

        return () => window.removeEventListener("resize", getHeight);
    }, []);


    const noticeHandler = e => {
        e.preventDefault();
        e.stopPropagation();

        if (notice.show) {
            dispatch({ type: "staticBtn/deactivate", name:"Notice" });
        } else {
            dispatch({ type: "staticBtn/update", name:"Notice" })
        }
        //reset focused icon from statusbar && modal && hamburger
        dispatch({ type: "current/reset" });
        dispatch({ type: "hamburger/deactivate" });
    }


    return (
        <MlAuto width="max-content" >
            <Ul>
                <Li marginRight={0}>
                    <StatusbarButton onClick={noticeHandler} title={"Alerts"}>
                        <Icon img={notice.icon}></Icon>
                    </StatusbarButton>
                    <Notice history={props.history} height={height} width={notice.show ? 367 : 0} zIndex={zIndex} right={0}></Notice>
                </Li>
            </Ul>
        </MlAuto>
    )
}