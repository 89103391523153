import React, { Component, Fragment, useCallback, useState, useEffect } from 'react';
//import { Route } from 'react-router';
//import { Layout } from './components/Layout';
//import { Home } from './components/Home';
//import { FetchData } from './components/FetchData';
//import { Counter } from './components/Counter';
import Login from './Login';
import Mobile from './Mobile';
import Container from './component/background/Container';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Callback from './Callback';
import { userManager } from './oidcClient';
import { GlobalStyle } from './style/GlobalCSS';
import Lock from './Lock';
import NotFound from './NotFound';
import { useDispatch, useSelector } from 'react-redux';



export default function App() {
    const isLock = useSelector(state => state.isLock);
    const [isMobile, setIsMobile] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLocked, setIsLocked] = useState(isLock);
    const dispatch = useDispatch();
/**
* before component mount, get user information to set loading component
*/
    //componentWillMount = () => {

    useEffect(() => {
        //if user is on mobile,
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            setIsMobile(true);

            //if user is not on mobile
        } else {
            userManager.getUser().then(res => {
                console.log(res)
                if (res) {
                    const email = res.profile.email;
                    const name = res.profile.name;
                    const nickName = res.profile.nickname;
                    const lockSttOnLocalStorage = JSON.parse(window.localStorage.getItem('lock'));
                    //console.log(`%cLOCK ON LOCAL STORAGE ${lockSttOnLocalStorage}`, "font-weight: 600; font-size: 2rem; color: yellow; background-color: pink;");
                    //console.log(`%cLOCK ON REDUX ${isLock}`, "font-weight: 600; font-size: 2rem; color: yellow; background-color: pink;");

                    //configure if the screen has been locked
                    if (isLock || lockSttOnLocalStorage === "true") {
                        setIsLocked(true);
                    } else {
                        setIsLocked(false);
                    }

                    //update as loggedIn
                    setIsLoggedIn(true);

                    //update user info
                    dispatch({ type: "userInfo/update", email, name, nickName });

                } else {
                    setIsLoggedIn(false);
                }
            });

        }

});

        return (
            <Fragment>
                <GlobalStyle />
                <BrowserRouter>
                    <Switch>
                        <Route exact path='/' render={props => (
                            isMobile ? <Redirect to='/nosupport' />
                                : isLoggedIn && isLocked ? <Lock {...props} />
                                    : isLoggedIn && !isLocked ? < Container {...props} />
                                        : <Login {...props} />
                            )}>
                        </Route>
                        <Route exact path='/login' render={(props) => <Login {...props} />} />
                        <Route exact path='/callback' render={(props) =>  <Callback {...props} />} />
                        <Route exact path='/nosupport' render={(props) => isMobile && < Mobile {...props} />} />
                        <Route component={NotFound} />
                    </Switch>
                </BrowserRouter>
            </Fragment>
        );

    /*
     * Un-comment  if you don't like to use Oidc client (without login service)
    */
        //return (
        //    <Fragment>
        //        <GlobalStyle />
        //        <BrowserRouter>
        //            <Switch>
        //                <Container />
        //                <Route exact path='/login' component={Login} />
        //                <Route exact path='/home' component={Container} />
        //                <Route exact path='/callback' component={Callback} />
        //            </Switch>
        //        </BrowserRouter>
        //    </Fragment>
        //);
}
