
/**
 * hamburger store for statusbar left side
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */


export default function hamburger(state = { show: false }, action) {

    switch (action.type) {
        case "hamburger/activate":
            return { ...state, show: true };

        case "hamburger/deactivate":
            return { ...state, show: false };

        default:
            return { ...state };

    }
}