import React from 'react';
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconHamburger, Input, MainBtn, MainBtnShape, SearchWrapper, DropdownModal, DropdownLeft, StatusbarButton, IconSm } from "../../../style/Component";
import { icon_finestra_lc, icon_finestra_w, icon_finestra } from "../../../style/Variable";

export default function Hamburgerbar(props) {
    // const [show, setShow] = useState(false);
    const zIndex = props.zIndex;
    const show = useSelector(state => state.hamburger).show;
    const defBtn = useSelector(state => state.defBtn);
    const modal = useSelector(state => state.modal);
    const dispatch = useDispatch();

    const hamburgerHandler = e => {
        e.preventDefault();
        e.stopPropagation();
        // setShow(prev => !prev);
        if (show) {
            dispatch({ type: "hamburger/deactivate" })
        } else {
            dispatch({ type: "hamburger/activate" })
        }

        //reset focused icon from statusbar && modal
        dispatch({ type: "current/reset" });
        dispatch({ type: "staticBtn/all/deactivate" });
        dispatch({ type: "ctxtmenu/all/deactivate" });
    }

    const dropdownHandler = e => {
        e.preventDefault();
        e.stopPropagation();
    }

    const actModalHandler = (e, name, info) => {
        e.preventDefault();
        e.stopPropagation();
        // e.stopPropagation();
        //this is a same function as actModalHandler on Bckground.js
        //if exist && add
        if (modal[name]) {
            if (typeof modal[name] !== undefined) {
                dispatch({ type: "modal/activate", name });
                //commented because activate and zIndex actually works in same say
                //if modal is already working, set zIndex higher of the modal
                // }else if (typeof modal[name] !== undefined && modal[name].active) {
                // dispatch({ type: "modal/zIndex", name })
            }
            //if doesn't exist && update
        } else {
            const y = Math.random() * (150 - 100) + 100;
            const x = Math.random() * (350 - 300) + 300;
            dispatch({ type: "modal/update", name, icon: info.icon, x, y, width: info.width, height: info.height });
        }

        dispatch({ type: "statusbar/update", name, icon: info.icon });
        dispatch({ type: "current/active", name })
        dispatch({ type: "hamburger/deactivate" });
    }

    const actWebHandler = (e, info) => {
        e.preventDefault();
        e.stopPropagation();
        
        window.open(info.url, info.target);
        dispatch({ type: "hamburger/deactivate" });
    }


    return (
        <Fragment>
            <div>
                <MainBtn onClick={hamburgerHandler} >
                    <IconHamburger img={show ? icon_finestra : icon_finestra}></IconHamburger>
                </MainBtn>
                <DropdownModal height={show ? 480 : 0} zIndex={zIndex} onClick={dropdownHandler} width={301.5}>
                    <DropdownLeft>
                        {Object.entries(defBtn).map(btn => {
                            const attr = btn[1];
                            const name = attr.name;
                            const imgPath = attr.icon;
                            const target = attr.target;
                            const isModal = target === "modal";
                            const info = isModal ? { icon: imgPath, width: attr.width, height: attr.height } : { url: attr.url, target: attr.target };
                            return (
                                <StatusbarButton key={name} title={name} onClick={e => isModal ? actModalHandler(e, name, info) : actWebHandler(e, info)}>
                                    <IconSm img={imgPath}></IconSm>
                                </StatusbarButton>
                            )
                        })}
                    </DropdownLeft>

                </DropdownModal>
            </div>
            <SearchWrapper>
                <Input type={"search"} height={"100%"} position={"19px 9px"} padding={"0 .5rem 0 2.8rem"}></Input>
            </SearchWrapper>
        </Fragment>
    )
}