const modalResizerModal = {
    resize: false,
    name: "",
    mousePosition: "",
    clickedDiv: ""
}



/**
 * for modal resizing
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */


export default function modalResizer( state = modalResizerModal, action ){
    switch(action.type){
        case "resize/start":
            return {
                resize: true,
                name: action.name,
                mousePosition: action.mousePosition,
                clickedDiv: action.clickedDiv
            }
        case "resize/end":
            return {
                resize: false,
                name: "",
                mousePosition: "",
                clickedDiv: ""
            }
        default: return { ...state };
    }
}