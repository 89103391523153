/***
 * 
 * attributes value setting
 * Attention: Did not inlucde ';'. Watch out when you use these properties.
 */

 export const RANDOM_NAME="list";



//statusbar attr
export const trans_2 = `.2s ease-in-out`;
export const trans_1 = `.1s ease-in-out`;
export const navTop_icon_hoverEffect = `linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0))`;
export const gray_dbdbdb = "#dbdbdb";
export const gray_ececec = "#ececec";
export const gray_cecece = "#cecece";
export const gray_c4c4c4 = "#c4c4c4";
export const gray_4d4d4d = "#4d4d4d";
export const gray_979797 = "#979797";
export const gray_f1f1f1 = "#f1f1f1";
export const red_pastel_light = "rgb(255, 210, 210)";
export const blue_1884ff = `#1884ff`;

export const navTop_height = `34`;
export const navTop_bgColor = gray_4d4d4d;
// export const navTop_li_height = `34px`;

//background icon attr


//background
//bgcontainer
export const bgHeight = `max-height: calc(100% - ${navTop_height}px);`;
// export const BgCotainerP = rem_05;


//file manager

export const FMContentListHeight = 24;
export const FMFocusedListBg = "rgba(0, 0, 0, 0.1)";
export const filterBtnSize = 24;
export const FMColumnWidth = 25;

export const FMColumnMinWidth = 40;
export const FMDirectoryWidth = 200;
export const FMDirectoryMinWidth = 20;



//store
export const FSTabFocusBgColor = "#fff";
export const FSCarouselDefaultImg = '/img/banner1.svg';
// export const FSCarouselImageWidth = '100%';
// export const FSCarouselImageHeight = '260px';
export const FSAllAppsCardMinWidth = 288;
export const FSAllAppsCardWidth = 380;
export const FSAllAppsCardHeight = 138;

//purchase app button
export const FSPurchaseAppsButtonColor = `white`;

//Job Manager
export const WMTitleHeight = "1rem";

//overflow
export const hidden = `overflow: hidden;`;
export const visible = `overflow: visible;`;

//icon size
export const iconBaseSizeWidth = "38px";
export const iconBaseSizeHeight = "20px";
export const iconSmSizeWidth = "20px";
export const iconSmSizeHeight = "22px";
export const iconSemiSizeWidth = "17px";
export const iconXsSizeWidth = "14px";
export const iconXsSizeHeight = "14px";
export const iconXxsSizeWidth = "12px";
export const iconXxsSizeHeight = "10px";
export const iconTinySizeWidth = "8px";
export const iconTinySizeHeight = "8px";
export const iconLgSizeWidth = "33px";
export const iconLgSizeHeight = "33px"
// export const iconXlSizeWidth = "45px";
// export const iconXlSizeHeight = "45px";
export const iconFullWidth = "100%";
export const iconHamburgerSizeWidth = "24px";
export const iconHamburgerSizeHeight = "21px";

export const iconBaseSize = `width: ${iconBaseSizeWidth}; height: ${iconBaseSizeHeight};`;
export const iconSmSize = `width: ${iconSmSizeWidth}; height: ${iconSmSizeHeight};`;
export const iconSemiSize = `width: ${iconSemiSizeWidth}; height: ${iconSemiSizeWidth};`;
export const iconXsSize = `width: ${iconXsSizeWidth}; height: ${iconXsSizeWidth};`;
export const iconXxsSize = `width: ${iconXxsSizeWidth}; height: ${iconXxsSizeWidth};`;
export const iconTinySize = `width: ${iconTinySizeWidth}; height: ${iconTinySizeHeight};`;
export const iconLgSize = `width: ${iconLgSizeWidth}; height: ${iconLgSizeWidth};`;
// export const iconXlSize = `width: ${iconXlSizeWidth}; height: ${iconXlSizeWidth};`;
export const iconFullSize = `width: 100%; height: 100%;`;

export const iconHamburgerSize = `width: ${iconHamburgerSizeWidth}; height: ${iconHamburgerSizeWidth};`;


export const icon_finestra = '/img/finestra-mainBtn.svg';
export const icon_finestra_w = '/img/finestra-w.svg';
export const icon_finestra_lc = '/img/finestra-lc.svg';

export const icon_export_b = '/img/folder-export-b.svg';
export const icon_edit_b = '/img/edit-b.svg';
export const icon_remove_b = '/img/trash-b.svg';
export const icon_check_b = '/img/check-b.svg';
export const Icon_minify_w = '/img/down-w.svg';
export const Icon_minify_b = '/img/down-b.svg';
export const Icon_maximize_w = '/img/full-w.svg';
export const Icon_backToOrigin_w = '/img/small-w.svg';
export const Icon_close_w = '/img/close-w.svg';
export const Icon_close_b = '/img/close-b.svg';
export const Icon_filter_b = '/img/filter-b.svg';
export const Icon_plus_b = '/img/plus-b.svg';
export const Icon_hamburger_b = '/img/menul-b.svg';
//fileManager navigator
export const Icon_upload_b = '/img/upload-b.svg';
export const Icon_search_b = '/img/search-b.svg';
export const Icon_arrowLeft_b = '/img/arrowL-b.svg';
export const Icon_arrowRight_b = '/img/arrowR-b.svg';
export const Icon_arrowUp_b = '/img/notailArrowUp-b.svg';
export const Icon_arrowDown_b = '/img/notailArrowDown-b.svg';
export const Icon_Reset_b = '/img/reset-b.svg';
export const Icon_folder_b = '/img/folder-b.svg';
export const Icon_folder_c = '/img/folder-c-2.svg';
export const Icon_logout = '/img/logout-b.svg';
export const Icon_lock = '/img/lock-b.svg';





//font
export const fontFamilyBase = "'Noto Sans KR', 'Noto Sans', sans-serif";
//font size
export const fontSizeBase = ".8rem";
export const fontSizeSm = ".75rem";
export const fontSizeXs = ".7rem";



//padding
export const columnPadding = ".3rem";
export const rowPadding = columnPadding;


//display
export const flex_a_center = `
    display: flex;
    align-items: center;
`;
export const flex_j_between = `
    ${flex_a_center}
    justify-content: space-between;
`;

export const flex_j_center = `
    ${flex_a_center}
    justify-content: center;
`;

export const flex_j_start = `
    ${flex_a_center}
    justify-content: flex-start;
`;

export const flex_j_end = `
    ${flex_a_center}
    justify-content: flex-end;
`;

export const flex_column = `
    display: flex;
    flex-direction: column;
`;

export const flex_none = `
    flex: none;
`;



//modal
//attributes value setting
export const modal_btn_hoverEffect = `linear-gradient(90deg,rgba(255,255,255,0),rgba(255,255,255,.2),rgba(255,255,255,0))`;
export const modalSttbarCurrentColor = "rgba(25, 25, 112, 1)";
export const modalSttbarColor = "rgba(55, 55, 85, 1)";
// export const modalSttbarCurrentColor = "rgba(0, 0, 0, .8)";
// export const modalSttbarColor = "rgba(0, 0, 0, .6)";


export const modalHeight = `28`;
export const modalSttbarListWidth = "2.5rem";

export const titleHeight = '39';

//export const navigatorHeight = '35';
//workmanager
export const workManagerBg = "rgba(0, 0, 0, 0.8)";


//progress bar
export const progressColor = "#1effc7";