
/**
 * Static icons store on statusbar right side
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */

const defCtxtmenu = {
    //"App Collection": { name: "App Collection", show: false },
    "Background": { name: "Background", show: false },
    "File Manager": { name: "File Manager", show: false }
};

export default function ctxtmenu(state = defCtxtmenu, action) {

    switch (action.type) {
        case "ctxtmenu/update":
            return { ...state, [action.name]: { ...state[action.name], show: true } };

        case "ctxtmenu/deactivate":
            return { ...state, [action.name]: { ...state[action.name], show: false } };

        case "ctxtmenu/all/deactivate":
            const update = state;
            Object.entries(update).forEach(each => {
                const name = each[0];
                const attr = each[1];
                attr.show = false;
            })
            //add others if there are extra apps
            return update;

        default:
            return { ...state };

    }
}

