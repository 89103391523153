import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import imgPath from '../../img/Finestrabg-bp.svg';
import { ContainerBase } from '../../style/Component';
import { FMColumnMinWidth, FMDirectoryMinWidth, navTop_height } from '../../style/Variable';
// import { FMColumnWidth } from '../../style/Variable';
import Statusbar from '../statusbar/Statusbar';
import Background from './Background';
// import { modalHeight } from '../../style/Variable';
import Lock from '../../Lock';

export default function Container(props) {
    const modal = useSelector(state => state.modal);
    const draggable = useSelector(state => state.draggable);
    const modalResizer = useSelector(state => state.modalResizer);
    const columnResizer = useSelector(state => state.columnResizer);
    const widthResizer = useSelector(state => state.widthResizer);
    const dispatch = useDispatch();
    const container = useRef();
    //const isLock = useSelector(state => state.isLock);
    //const [locked, setLocked] = useState(isLock);


    const modalMinimumSize = {
        width: 400,
        height: 400,
    };

    const dragEndHandler = e => {
        let isDragging = draggable.mouseOn;
        if (!isDragging) {
            return;
        }

        dispatch({ type: "draggable/end" });
    }

    /**
    * set div position when user drops a modal after dragging
    * @param {any} e - event param
    */
    const dropHandler = e => {
        e.preventDefault();
        e.stopPropagation();
        let isDragging = draggable.mouseOn;

        if (!isDragging) {
            return;
        }
        //console.log('dropping the div')

        const mousePosition = draggable.mousePosition;
        const name = draggable.name;
        const target = draggable.target;
        //target.style.display = "block";

        //const clonedModal = document.querySelector('#drag-ghost');
        //target.removeChild(clonedModal);

        // const getInt = /\d+/;
        // const navHeight = getInt.exec(getNavTopHeight())[0];

        const computedY = e.pageY - mousePosition.y - parseInt(navTop_height);
        //calc modal positioin
        let modalPosition = {
            x: e.pageX - mousePosition.x,
            // y: computedY <= 0 ? 0 : computedY
            y: computedY < 0 ? 0.1 : computedY
        };
        //console.log(modalPosition.x, modalPosition.y)

        //dispatch({ type: "draggable/end" });
        dispatch({ type: "modal/position", name, x: modalPosition.x, y: modalPosition.y })

    };

    const resetFocusHandler = e => {
        e.preventDefault();
        e.stopPropagation();

        //reset focused icon from statusbar && modal
        dispatch({ type: "current/reset" });
        //reset hamburger if it is opened
        dispatch({ type: "hamburger/deactivate" });
        dispatch({ type: "staticBtn/all/deactivate" });
        dispatch({ type: "ctxtmenu/all/deactivate" });

    }

    /**
    * get name of modalResizer modal
    */
    const getName = () => {
        return modalResizer.name;
    }

    // const getNavTopHeight = () =>{
    //     const getInt = /\d+/;
    //     const navHeight = getInt.exec(modalHeight)[0];
    //     return parseFloat(navHeight);
    // }
    /**
    * get current position of the modal
    */
    const getOriginPosition = () => {
        const target = modal[getName()];
        // console.log(target, target.x)

        return { x: parseFloat(target.x), y: parseFloat(target.y) };
    }

    /**
    * get current size of the modal
    */
    const getOriginSize = () => {
        const target = modal[getName()];

        return { width: parseFloat(target.width), height: parseFloat(target.height) };
    }

    /**
    * send redux store the position and the size of the modal
    */
    const updateModal = (size, position) => {
        // store.dispatch(setPositionAndSizeOfModal(getName(), position, size));
        dispatch({ type: "modal/update", name: getName(), x: position.x, y: position.y, width: size.width, height: size.height })
    }

    /**
     * send redux store the size of the modal
     * @param {object} size
     */
    const updateModalSize = (size) => {
        // store.dispatch(setSizeOfModal(getName(), size));
        dispatch({ type: "modal/size", name: getName(), width: size.width, height: size.height });
    }

    /**
     * check if resized width is narrower than the width of minimum size
     * @param {number} width - resized width
     */
    const checkIfMinimumWidth = (width) => {
        return (width < modalMinimumSize.width) ? true : false;
    }

    /**
     * check if resized height is shorter than the height of minimum size
     * @param {number} height - resized height
     */
    const checkIfMinimumHeight = height => {
        return (height < modalMinimumSize.height) ? true : false;
    }

    /**
     * calc width when mouse resizing position DOES effect of modal position
     * @param {number} mousePositionX - e.pageX which is the mouse x position
     */
    const getWidthFromUnpositionedModal = (mousePositionX) => {
        let width = (getOriginPosition().x + getOriginSize().width) - mousePositionX;

        // console.log(getOriginPosition().x, getOriginSize().width, mousePositionX)
        return width;
    }

    /**
     * calc width when mouse resizing position DOES NOT effect of modal position
     * @param {number} mousePositionX - e.pageX which is the mouse x position
     */
    const getWidthFromPositionedModal = mousePositionX => {
        let width = mousePositionX - getOriginPosition().x;
        return width;
    }

    /**
   * calc height when mouse resizing position DOES effect of modal position
   * @param {number} mousePositionY - e.pageY which is the mouse y position
   */
    const getHeightFromUnpositionedModal = mousePositionY => {
        // let height = (getOriginPosition().y + getOriginSize().height) - mousePositionY + getNavTopHeight();
        let height = (getOriginPosition().y + getOriginSize().height) - mousePositionY + Number(navTop_height);
        return height;
    }

    /**
    * calc height when mouse resizing position DOES NOT effect of modal position
    * - height must minus status bar height, otherwise, it gets the size includes of status bar height
    * @param {number} mousePositionY - e.pageY which is the mouse y position
    */
    const getHeightFromPositionedModal = mousePositionY => {
        // let height = mousePositionY - getOriginPosition().y - getNavTopHeight();
        let height = mousePositionY - getOriginPosition().y - Number(navTop_height);
        return height;
    }

    /**
   * if width minimum ? return null : set modal size with changed width
   * @param {number} width - changed width
   */
    const setSizeWithWidth = width => {
        //check if width < min width => return;
        let isMinimum = checkIfMinimumWidth(width);
        if (isMinimum) {
            return null;
        }

        let size = {
            width,
            height: getOriginSize().height,
        };

        return size;
    }


    /**
    * if height minimum ? return null : set modal size with changed height
    * @param {number} height - changed height
    */
    const setSizeWithHeight = height => {
        let isMinimum = checkIfMinimumHeight(height);
        if (isMinimum) {
            return null;
        }
        let size = {
            width: getOriginSize().width,
            height,
        }
        return size;
    };


    /**
    * set modal x position with mouse x position
    * @param {number} mousePositionX - changed x position
    */
    const setPositionWithMouseX = mousePositionX => {
        let position = {
            x: mousePositionX,
            y: getOriginPosition().y
        };
        return position;
    }

    /**
    * set modal y position with mouse y position
    * @param {number} mousePositionY - changed y position
    */
    const setPositionWithMouseY = mousePositionY => {
        // console.log(getOriginPosition().x)
        //set new position
        let position = {
            x: getOriginPosition().x,
            // y: mousePositionY - getNavTopHeight(),
            y: mousePositionY - Number(navTop_height),
        };
        return position;
    }

    const resizeModal = (e) => {
        const LEFT = "left";
        const RIGHT = "right";
        const BOTTOM = "bottom";
        const TOP = "top";
        const TOP_RIGHT = "topRight";
        const TOP_LEFT = "topLeft";
        const BOTTOM_RIGHT = "bottomRight";
        const BOTTOM_LEFT = "bottomLeft";

        let mousePositionX = e.pageX;
        let mousePositionY = e.pageY;
        let clickedFrom = modalResizer.clickedDiv;

        //check min size
        let isMinWidth = (getOriginSize().width === modalMinimumSize.width);
        let isMinHeight = (getOriginSize().height === modalMinimumSize.height);
        let isWidthNarrow = false;
        let isHeightShort = false;

        let width = 0;
        let height = 0;
        let size = 0;
        let position = 0;

        switch (clickedFrom) {
            case LEFT:
                container.current.style.cursor = 'ew-resize';

                //when it's minimum width && mouse is inside of the modal => wait until mouse come back on right position
                if (isMinWidth && (mousePositionX > getOriginPosition().x)) { return; }

                width = getWidthFromUnpositionedModal(mousePositionX);
                // console.log(width)
                size = setSizeWithWidth(width);
                // console.log(size, 'size')
                if (size) {
                    position = setPositionWithMouseX(mousePositionX);
                    updateModal(size, position);
                }
                break;

            case RIGHT:
                container.current.style.cursor = 'ew-resize';

                //when it's minimum width && mouse is inside of the modal => wait until mouse come back on right position
                if (isMinWidth && (mousePositionX < getOriginPosition().x + modalMinimumSize.width)) {
                    return;
                }

                width = getWidthFromPositionedModal(mousePositionX);
                size = setSizeWithWidth(width);
                size && updateModalSize(size);
                break;

            case BOTTOM:
                //set mouse cursor
                container.current.style.cursor = 'ns-resize';
                //check mouse position when the height is minimum
                if (isMinHeight && (mousePositionY < (getOriginPosition().y + modalMinimumSize.height))) { return; }

                height = getHeightFromPositionedModal(mousePositionY);
                size = setSizeWithHeight(height);
                size && updateModalSize(size);
                break;

            case TOP:
                container.current.style.cursor = 'ns-resize';

                //check mouse position when the height is minimum
                // if (isMinHeight && (mousePositionY > getOriginPosition().y + getNavTopHeight())) { return; }
                if (isMinHeight && (mousePositionY > getOriginPosition().y)) { return; }

                //get new height
                height = getHeightFromUnpositionedModal(mousePositionY);
                size = setSizeWithHeight(height);
                if (size) {
                    position = setPositionWithMouseY(mousePositionY);
                    updateModal(size, position);
                }
                break;
            case TOP_RIGHT:
                container.current.style.cursor = 'nesw-resize';
                //get new width
                width = getWidthFromPositionedModal(mousePositionX);
                //get new height
                height = getHeightFromUnpositionedModal(mousePositionY);
                //set new position
                position = {
                    x: getOriginPosition().x,
                    // y: mousePositionY - getNavTopHeight(),
                    y: mousePositionY,
                }
                //check modal size
                isWidthNarrow = width < modalMinimumSize.width;
                isHeightShort = height < modalMinimumSize.height;
                if (isWidthNarrow && isHeightShort) {
                    return;
                } else if (isWidthNarrow && !isHeightShort) {
                    width = modalMinimumSize.width;
                } else if (!isWidthNarrow && isHeightShort) {
                    size = {
                        width,
                        height: getOriginSize().height,
                    };
                    updateModalSize(size);
                    break;
                }
                //set new size
                size = {
                    width,
                    height,
                };
                updateModal(size, position);
                break;

            case TOP_LEFT:
                container.current.style.cursor = 'nwse-resize';

                width = getWidthFromUnpositionedModal(mousePositionX);
                height = getHeightFromUnpositionedModal(mousePositionY);
                position = {
                    x: mousePositionX,
                    // y: mousePositionY - getNavTopHeight(),
                    y: mousePositionY,
                }
                //check modal size
                isWidthNarrow = width < modalMinimumSize.width;
                isHeightShort = height < modalMinimumSize.height;
                if (isWidthNarrow && isHeightShort) {
                    return;
                } else if (isWidthNarrow && !isHeightShort) {
                    size = {
                        width: getOriginSize().width,
                        height,
                    };
                    position.x = getOriginPosition().x;
                } else if (!isWidthNarrow && isHeightShort) {
                    size = {
                        width,
                        height: getOriginSize().height,
                    };
                    position.y = getOriginPosition().y;
                } else {
                    //set new size
                    size = {
                        width,
                        height,
                    };
                }
                updateModal(size, position);

                break;
            case BOTTOM_RIGHT:
                container.current.style.cursor = 'nwse-resize';

                width = getWidthFromPositionedModal(mousePositionX);
                checkIfMinimumWidth(width) && (width = modalMinimumSize.width);

                height = getHeightFromPositionedModal(mousePositionY);
                checkIfMinimumHeight(height) && (height = modalMinimumSize.height);

                size = {
                    width,
                    height,
                };

                updateModalSize(size);
                break;
            case BOTTOM_LEFT:
                container.current.style.cursor = 'nesw-resize';

                width = getWidthFromUnpositionedModal(mousePositionX);
                height = getHeightFromPositionedModal(mousePositionY);

                position = {
                    x: mousePositionX,
                    y: getOriginPosition().y,
                }
                //check modal size
                isWidthNarrow = width < modalMinimumSize.width;
                isHeightShort = height < modalMinimumSize.height;
                if (isWidthNarrow && isHeightShort) {
                    return;
                } else if (isWidthNarrow && !isHeightShort) {
                    size = {
                        width: getOriginSize().width,
                        height,
                    };
                    updateModalSize(size);
                    break;
                } else if (!isWidthNarrow && isHeightShort) {
                    size = {
                        width,
                        height: getOriginSize().height,
                    };
                    //set new position
                    position.y = getOriginPosition().y;
                } else {
                    size = {
                        width,
                        height,
                    };
                }
                updateModal(size, position);
                break;

            default: break;
        }
    }

    /**
     * update your resized column width of modal. It will be one of 'file manager' or 'All apps'.
     * @param {any} e
     */
    const resizeColumn = (e) => {
        //set mouse cursor
        container.current.style.cursor = 'col-resize';
        //name is the triggered modal
        const name = columnResizer.name;

        const newWidth = e.pageX - columnResizer[name].listX;
        const newSiblingWidth = columnResizer[name].siblingInfo.right - e.pageX;
        const rectWidth = columnResizer[name].rectWidth;

        //sibling is the triggered column's sibling elements
        const sibling = columnResizer[name].sibling;
        if (newWidth < FMColumnMinWidth || newSiblingWidth < FMColumnMinWidth) return;

        const width = newWidth / rectWidth * 100;
        const siblingWidth = newSiblingWidth / rectWidth * 100;
        //target is the triggered column
        const target = columnResizer[name].target;
        dispatch({ type: "resizeColumn/update", name, target, width, sibling, siblingWidth })
    };


    /**
     * update your resized sidebar width of modal. It will be one of 'file manager' or 'All apps'
     * @param {any} e
     */
    const resizeWidth = e => {
        //set mouse cursor
        container.current.style.cursor = 'col-resize';

        // const width = e.pageX - widthResizer.x;
        const target = widthResizer.target;
        const width = e.pageX - widthResizer[target].x;
        //if width is narrower than the minimum size of it, return;
        if (width < FMDirectoryMinWidth) return;
        dispatch({ type: "resizeWidth/update", name: target, width });
        // console.log(newWidth)
    };

    /**
    * handle resizing depends on where mouse is on the taget modal
    * @param {any} e - event param
    */
    const resizeHandler = e => {
        e.preventDefault();
        e.stopPropagation();

        //get which modalResizer div clicked to resize

        if (modalResizer.resize) {
            resizeModal(e);
        } else if (columnResizer.resize) {
            resizeColumn(e);
        } else if (widthResizer.resize) {
            resizeWidth(e);
        }

    }

    /**
     * when resizing is finished, send redux alert that it's finished
     * @param {any} e
     */
    const finishResizeHandler = e => {
        e.preventDefault();
        e.stopPropagation();
        //set mouse cursor back to normal
        container.current.style.cursor = "default";

        //if user was resizing, quit resize
        const ifResized = modalResizer.resize;
        ifResized && dispatch({ type: "resize/end" });

        const ifColumnResized = columnResizer.resize;
        const columnResizedTarget = columnResizer.target;
        ifColumnResized && dispatch({ type: "resizeColumn/end", name: columnResizedTarget });

        const ifWidthResized = widthResizer.resize;
        const widthResizedTarget = widthResizer.target;
        ifWidthResized && dispatch({ type: "resizeWidth/end", name: widthResizedTarget });
    }

    const contextMenuHandler = e => {
        e.preventDefault();
        e.stopPropagation();

    }

            //onTouchMove={draggable.mouseOn && dropHandler} onTouchEnd={draggable.mouseOn && dragEndHandler} 
    //locked ? <Lock />
    //    :
    //onTouchMove = { dropHandler } onTouchEnd = { dragEndHandler }
    return (
        <ContainerBase img={imgPath}

            /*onDrop={dropHandler} onDragOver={e => e.preventDefault()}*/
            onClick={resetFocusHandler} ref={container}
            onMouseMove={modalResizer.resize || columnResizer.resize || widthResizer.resize ? resizeHandler
                : draggable.mouseOn ? dropHandler : null}
            onMouseUp={modalResizer.resize || columnResizer.resize || widthResizer.resize ? finishResizeHandler
                : draggable.mouseOn ? dragEndHandler : null}
            onMouseLeave={e => { dropHandler(e); dragEndHandler(e); }} onContextMenu={contextMenuHandler}
        >
            <Statusbar history={props.history}></Statusbar>
            <Background></Background>
        </ContainerBase>
    );
}
