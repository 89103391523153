import React from 'react';
import { useState } from "react";
import { Filter, FilterBtn, FilterBox, Input, NaviButton, Li, IconXs, Button, Ul, Col12 } from "../../style/Component"
import { filterBtnSize, Icon_filter_b, Icon_arrowLeft_b, Icon_Reset_b, Icon_arrowRight_b } from "../../style/Variable"

export default function Navigator() {
    //icons
    const navigators = [Icon_arrowLeft_b, Icon_arrowRight_b, Icon_Reset_b];
    //filter info
    const filters = ["App", "Video", "Image", "Folder", "File"];
    const [isFilterOpened, setIsFilterOpened] = useState(false);
    
    const filterHandler = e =>{
        e.preventDefault();
        e.stopPropagation();
        setIsFilterOpened(prev => !prev);
    };

    const filterResultHandler = (e, filterName) => {
        e.preventDefault();
        e.stopPropagation();
        
    }
    return (
        <Col12 >
            <Ul>
                {navigators.map(btn =>
                    <Li key={btn}>
                        <NaviButton isNavi={true}>
                            <IconXs img={btn}></IconXs>
                        </NaviButton>
                    </Li>
                )}
                <Li full={true}>
                    <Input height={24} padding={"0 .5rem"}></Input>
                </Li>
                <Li>
                    <form>
                        <Input type="search" placeholder="Search" height={26} position={"4px 4px"} padding={"0 26px 0 28px"}></Input>
                        {/*<Filter onClick={filterHandler}>
                            <FilterBtn>
                                <IconXs img={Icon_filter_b}></IconXs>
                            </FilterBtn>
                            <FilterBox show={isFilterOpened} height={filters.length * filterBtnSize}>
                                <ul>
                                    {filters.map(filter => (
                                        <li key={filter}>
                                            <Button onClick={e => filterResultHandler(e, filter)}>{filter}</Button>
                                        </li>
                                    ))}
                                </ul>
                            </FilterBox>
                        </Filter>*/}    
                    </form>
                </Li>
            </Ul>
        </Col12>
    )
}