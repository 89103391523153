// Copyright(c) CLEW Inc. All rights reserved.
//
// Writter: Lee, Jihye
// Last Modified: 2021.08.24
//
// Dependency:
//  - styled-component
//
// Description
// - Finestra Landing Page (Login Page)



import React, { Component, useEffect, useState } from 'react';
//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
import { userManager } from './oidcClient';
import styled from 'styled-components';
import { IconLg, P, A, IconFullCover } from './style/Component';

export default function Login(props){
    
    const mt_1 = {
        marginTop: "1rem"
    };

    const mr_1 = {
        marginRight: "1rem"
    };

    const mt_6 = {
        marginTop: "6rem"
    };

    const text_gray = {
        color: "#4d4d4d"
    };

    const w_45 = {
        width: "45%"
    };


    const [bgRandomImg, setBgRandomImg] = useState("");
    const signUpPage = "https://id.geneers.com/Account/SignUp";
    

    useEffect(() => {
        //let isMounted = true;

        //const url = 'https://api.geneers.com/backgroundimage/random';
        //fetch(url, {
        //    method: 'GET',
        //}).then(res => res.blob()
        //).then(res => {
        //    //console.log('res: ', res)

        //    if (isMounted) {
        //        const url = URL.createObjectURL(res);
        //        setBgRandomImg(url);

        //        //URL.revokeObjectURL(url);
        //    }

        //}).catch(err => {
        //    if (isMounted) {
        //        console.log('Not able to connect with geneers api web site. Instead using picsum');
        //        setBgRandomImg('https://picsum.photos/1024/1080');
        //    }
        //});

        //return () => { isMounted = false };
    }, []);
    //this.callback = props;

    const toLoginPage = e => {
        e.preventDefault();
        userManager.signinRedirect();
    }

    const toSignUpPage = e => {
        e.preventDefault();
        window.open(signUpPage, '_blank');
    }

    const flex_end = {
        alignItems: "flex-end"

    }

    //render() {
    //console.log(this.callback);

        return (
            <Container img={"https://api.geneers.com/backgroundimage/random"} img_onerror={"https://picsum.photos/1024/1080"}>
                {/*}
                <NavContainer>
                    <Nav>
                        <IconContainer>
                            <Icon_Conditional img={"./img/logo-geneers.svg"} img_md={"./img/logo-geneers-light.svg"}></Icon_Conditional>
                        </IconContainer>
                    </Nav>
                </NavContainer>
                */}

                <LoginBox >
                    <FullWidth_Column mt={"5rem"} mt_md={"2rem"}>
                        <Icon img={"./img/logo-geneers.svg"} ></Icon>
                        <P style={Object.assign(mt_1, text_gray)}>CAE Solver Launcher System</P>
              
                        <Button_Block state="login" onClick={toLoginPage} style={mt_6}>Sign In</Button_Block>
                        <Ul>
                            <li style={w_45}>
                                <Line></Line>
                            </li>
                            <li style={text_gray}>
                                OR
                            </li>
                            <li style={w_45}>
                                <Line></Line>
                            </li>
                        </Ul>
                        <Button_Block_Outline state="signUp" onClick={toSignUpPage}>Sign Up</Button_Block_Outline>
                    </FullWidth_Column>
                    <Footer >
                        <Div_Md_None>
                            <A style={mr_1}>Terms of Use</A>
                            <A style={mr_1}>&nbsp; Privacy Policy</A>
                            <A >&nbsp; Customer Service</A>
                        </Div_Md_None>
                        <Div>
                            <P >Copyright © CLEW Inc. All Rights reserved.</P>
                        </Div>
                    </Footer>
                </LoginBox>
                <Footer_Md>
                    <Div style={mr_1}>    
                        <A style={mr_1}>Terms of Use</A>
                        <A style={mr_1}>&nbsp; Privacy Policy</A>
                        <A>&nbsp; Customer Service</A>
                    </Div>
                    {/*
                    <div>
                        <P >Copyright © CLEW Inc. All Rights reserved.</P>
                    </div>
                    */}
                </Footer_Md>
            </Container>
        );
    //}
}


const DisplayFlexCenterAll = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div.attrs(props => ({
    style: {
        backgroundImage: `url(${props.img}), url(${props.img_onerror}), linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) )`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover"

    }
}))`
    width: 100vw;
    min-height: 100vh;
    margin:0;
    padding:0;


    @media only screen and (min-width: 768px){
        display: grid;
        grid-template-rows: 1fr;
        justify-items: center;
        grid-template-rows: 1fr 30px;
    }
`;

        //width: calc(100% - 3rem);
        //height: calc(100% - 2rem);
//const NavContainer = styled.nav`
//    align-self: flex-start;
//    padding: 1rem 2rem;

//    @media only screen and (max-width: 768px){
//        position: absolute;
//        left: 0;
//        padding: 1rem 3rem;
//    }
// `;

//const Nav = styled(DisplayFlexCenterAll)`
//    width: 100%;
//    height: 100%;
//    display: flex;
  
// `;


const IconContainer = styled.div`
    width: 151px;
    height: 24px;

    @media only screen and (max-width: 768px){
        width: 100px;
        height: 16px;
    }
 `;



    //justify-content: flex-start;
const LoginBox = styled(DisplayFlexCenterAll)`
    align-self: center;
    flex-direction: column;
    padding: 3rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .6);
    width: 100%;
    max-width: calc(400px - 6rem);
    height: calc(35rem - 6rem);
    min-height: 450px;
    background-color: rgba(255,255,255,.92);
    justify-content: space-between;

    @media only screen and (max-width: 768px){
        background-color: white;
        min-width: calc(100vw - 6rem);
        height: calc(100vh - 6rem);
    }
    
`;

const FullWidth = styled(DisplayFlexCenterAll)`
    width:100%;
    display: flex;
`;

const FullWidth_Column = styled(FullWidth)`
    flex-direction: column;
    margin-top: ${props => props.mt};

     @media only screen and (min-width: 768px){
        margin-top: ${props => props.mt_md};
    }

    
`;

const Footer = styled.footer`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    
    a, p{
        color: black !important;
    }

`;


const Footer_Md = styled(Footer)`
    display: none !important;
    background-color: rgba(0,0,0,.2);
    align-items: flex-end;

    @media only screen and (min-width: 768px){
        display: flex !important;
        width: 100%;
        margin-top: 0;
        a, p{
                color: white !important;
        }
    }
`;
//width: 100 %;
//justify - content: center;
//align - items: cetner;
//and for the second div => position: absolute; right: .5rem;

const Div = styled.div`
    display: block;
    text-align: center;
`;

const Div_Md_None = styled.div`
    display: block;
    text-align: center;
    @media only screen and (min-width: 768px){
        display: none !important;
        
    }
`;

const Button = styled.button`
    border:0;
    outline:0;
    font-size: .8rem;
    background-color: inherit;
    display: flex;
    align-items:center;
    justify-content:center;
    &:hover{
        cursor: pointer;

    }
`;
const Button_Block = styled(Button)`
   padding: 10px 20px;
    background-color: rgba(0, 0, 0, .6);
    color: white;
    width: 100%;
    
`;

const Button_Block_Outline = styled(Button_Block)`
    outline: 1px solid rgba(0, 0, 0, .6);
    background-color: white;
    color: rgba(0, 0, 0, .6);
`;

const Ul = styled.ul`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: .5rem; 0;
`;

const Line = styled.div`
    width: 100%;
    height : 1px;
    background: #4d4d4d;
`;

const Icon = styled.i.attrs(props => ({
    style: {
        backgroundImage: `url(${props.img})`,
    }
}))`
height: 30px;
background-size: contain;
display: inline-block;
background-repeat: no-repeat;
background-position: center;
width: 100%;
`;

const Icon_Conditional = styled(IconFullCover)`

    @media only screen and (min-width: 768px){
        background-image: url("${props => props.img_md}") !important;
    }

`;

    //background-color: ${props => props.state === "login" ? whiteIconBg : hoverIconBg};