
/**
 * Static icons store on statusbar right side
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */

const defStaticBtns = {
    "Notice": { name: "Notice", icon: "/img/notice-w.svg", show: false }
};

export default function staticBtn(state = defStaticBtns, action) {

    switch (action.type) {
        case "staticBtn/update":
            return { ...state, [action.name]: { ...state[action.name], show: true } };

        case "staticBtn/deactivate":
            return { ...state, [action.name]: { ...state[action.name], show: false } };

        case "staticBtn/all/deactivate":
            const update = state;
            Object.entries(update).forEach(each => {
                const name = each[0];
                const attr = each[1];
                attr.show = false;
            })
            //add others if there are extra apps
            return update;

        default:
            return { ...state };

    }
}