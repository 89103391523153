import React from 'react';
import { Container } from "../../../../style/Component";
import Speed from "./speed";
import UsageRecord from "./usageRecord";
import Volume from "./volume";


export default function JobManager() {

    return (
        <Container height={"100%"} flowy={true}>
           <Volume></Volume>
           <Speed></Speed>
           <UsageRecord></UsageRecord>
        </Container>
    )
}