const defSysItems = {
    modal: {
        //'Search': { name: "Search", icon: '/img/search-b.svg', width: "560", height: "508" },
        'File Manager': { name: "File Manager", icon: '/img/filemanager-c.svg', width: "960", height: "596", date: new Date().toLocaleString()  },
        'Account': { name: "Account", icon: '/img/account-w.svg', width: "560", height: "436", date: new Date().toLocaleString()  },
        'Setting': { name: "Setting", icon: '/img/setting-c.png', width: "560", height: "436", date: new Date().toLocaleString()  },
        'Job Manager': { name: "Job Manager", icon: '/img/jobmanager-c.svg', width: "609", height: "731.2", date: new Date().toLocaleString()  },
        'Store': { name: "Store", icon: '/img/store-c.svg', width: "640", height: "836", date: new Date().toLocaleString()  },
        //'App Collection': { name: "App Collection", icon: '/img/applibrary-c.svg', width: "657", height: "518" },
    },
    web: {
        'IE4.IMCA': { name: "IE4.IMCA", icon: "/img/G-LOGO-v3.svg", target: '_blank', url: "https://imca.geneers.com", date: new Date().toLocaleString() },
        'IE4.LSPM': { name: "IE4.LSPM", icon: "/img/G-LSPM-LOGO.svg", target: '_blank', url: "https://lspm.geneers.com", date: new Date().toLocaleString() },
        'IE4.LFX': { name: "IE4.IMCA with FEM", icon: "/img/imca_fem_logo_color.svg", target: '_blank', url: "https://lfx.geneers.com", date: new Date().toLocaleString() },
        'IE4.EMF': { name: "IE4.EMF", icon: "/img/imca_fem_logo_color.svg", target: '_blank', url: "https://emf.geneers.com", date: new Date().toLocaleString() },
    },
};                    

/**
 * default systems that is availible
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */


export default function defSys( state = defSysItems, action){
    switch (action.type){
        default:
            return state;
    }
}


// const systemItems =[
//     { name: 'IE4', icon: "/img/G-LOGO-v3.svg", target: '_blank', url: "https://ie4.clew.dev/"  },
// ];

// export default function sysReducer( state = systemItems, action){
//     switch (action.type){
//         case "getAct":
//             return [ ...state ];
//         default:
//             return [ ...state ];
//     }
// }
