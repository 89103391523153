import Oidc, { UserManager } from "oidc-client";

/**
 * config option to use UserManager
 * Use https://localhost:31001 for authority debug, and turn on id.geneers.com project in visual studio 
*/

const config = {
    authority: "https://id.geneers.com/",
     //authority: "https://127.0.0.1:31001/",
    client_id: "finestra",
    redirect_uri: window.location.origin + "/callback",
    client_secret:'7bb0e616-2c3d-49c2-a1df-916196b7dda8',
    post_logout_redirect_uri: window.location.origin + "/",
    response_type: "code",
    scope: "openid profile email resource-api-v1",
    loadUserInfo: true,
    //filterProtocolClaims: false
};

//Oidc.Log.logger = window.console;
//Oidc.Log.level = Oidc.Log.DEBUG;


export const userManager = new UserManager(config);


userManager.events.addUserLoaded(function (user) {
    console.log("User here loaded");
});
userManager.events.addUserUnloaded(function () {
    console.log("User logged out locally");
});
userManager.events.addAccessTokenExpiring(function () {
    console.log("Access token expiring...");
});
userManager.events.addSilentRenewError(function (err) {
    console.log("Silent renew error: " + err.message);
});
userManager.events.addUserSignedIn(function (e) {
    console.log("user logged in to the token server");
});
userManager.events.addUserSignedOut(function () {
    console.log("User signed out of OP");
});
